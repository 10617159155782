import { TemperatureScale } from '@sdk/api';

export const convertTemperature = (celsius?: number, tempScale?: TemperatureScale) => {
  if (!Number.isFinite(celsius)) return;
  return tempScale === TemperatureScale.Fahrenheit ? celsius! * (9 / 5) + 32 : celsius;
};

export const getTempScaleSymbol = (tempScale?: TemperatureScale) =>
  tempScale === TemperatureScale.Fahrenheit ? '°F' : '°C';

export const getFormattedTemperature = (celsius?: number, tempScale?: TemperatureScale) => {
  const converted = convertTemperature(celsius, tempScale);
  return converted ? converted.toFixed(1) + getTempScaleSymbol(tempScale) : undefined;
};
