import {
  type BatchDetailView,
  type BatchEventView,
  BatchEventViewTypeEnum,
  type ListBatchEventView,
} from '@sdk/api';

const sortByCreationTime = (a: BatchEventView, b: BatchEventView) =>
  new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();

export const getCommentsBlocks = (batch?: BatchDetailView, events?: ListBatchEventView) => {
  const comments = events?.items
    .filter((event) => event.type === BatchEventViewTypeEnum.Comment)
    .sort(sortByCreationTime)
    .map((event) => ({
      key: event.id,
      date: event.createdAt,
      user: event.user?.fullName,
      text: event.text,
    }));

  comments?.push({
    key: 'conclusion',
    date: '',
    user: '',
    text: batch?.conclusion,
  });

  return comments ?? [];
};

export const getNotifications = (events?: ListBatchEventView) => {
  const notifications = events?.items
    .filter((e) => e.type !== BatchEventViewTypeEnum.Comment)
    .sort(sortByCreationTime);

  return notifications ?? [];
};
