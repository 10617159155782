import { BatchIndicator } from '@app/constants/enums';
import { useDrawer, usePermissions } from '@app/utils/hooks';
import { usePrefetchBatchStats, useUpdateBatch } from '@data/hooks';
import { type BatchDetailView, BatchViewStatusEnum, type UpdateBatchBody } from '@sdk/api';
import { AntCard, AntFlex, AntSpace, AntTypography, EditButton } from '@ui/components';
import { breakpointWidth, colors, fontSize } from '@ui/constants';
import { Image } from 'antd';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useTranslation } from 'react-i18next';

import { EditBatchPlanDrawer } from '../../Drawers/EditBatch';
import { getFloorPlanSrc } from '../../utils';
import { CloseDetails } from './CloseDetails';
import { Indicator } from './Indicator';
import { EmptyOvoscan, Ovoscan } from './Ovoscan';
import styles from './styles.module.css';

dayjs.extend(relativeTime);

export const BatchStatus = ({ batch }: { batch: BatchDetailView }) => {
  const { t } = useTranslation();
  const { isNestBornAdmin, getIsHatcheryAdmin } = usePermissions();
  const { Text } = AntTypography;

  usePrefetchBatchStats(batch.id);

  const getSensors = (data?: BatchDetailView) => {
    if (!data?.sensors) return [];
    return [...Array(8).keys()].map((i) => {
      const sensor = data.sensors.find((s) => s.position === i + 1);
      return sensor ? (
        <Ovoscan key={sensor.id} batch={batch} sensor={sensor} />
      ) : (
        <EmptyOvoscan key={i} position={i + 1} />
      );
    });
  };

  const { isOpen: isPlanOpen, toggleIsOpen: toggleIsPlanOpen } = useDrawer();
  const { mutateAsync: updateBatch, isPending } = useUpdateBatch();

  const onSubmit = async (values: UpdateBatchBody) => {
    if (!batch) return;
    await updateBatch({ id: batch.id, updateBatchBody: values });
    toggleIsPlanOpen();
  };

  const hatcheryId = batch?.hatchery?.id;
  const isAdmin = isNestBornAdmin || (!!hatcheryId && getIsHatcheryAdmin(hatcheryId));
  const isClosed = batch?.status === BatchViewStatusEnum.Closed;
  const hatchedPercentage = batch?.hatchedPercentage ?? 0;

  return (
    <AntSpace direction="vertical" size="large" className={styles.container}>
      {!isClosed && (
        <AntCard className={styles.container}>
          <AntSpace direction="vertical" size="large" className={styles.container}>
            <AntFlex className={styles.flexContainer}>
              <Text strong style={{ fontSize: fontSize.xl }}>
                {t('main_indicators')}
              </Text>
              {!!batch?.maxMeasurementDate && (
                <Text style={{ color: colors.grey500 }}>
                  {t('last_update', { time: dayjs(batch.maxMeasurementDate).fromNow() })}
                </Text>
              )}
            </AntFlex>

            <AntFlex
              gap={8}
              className={styles.flexContainer}
              style={{ maxWidth: breakpointWidth.xl }}
            >
              <AntSpace direction="vertical">
                <Indicator type={BatchIndicator.HatchedPercentage} value={hatchedPercentage} />
                <Indicator type={BatchIndicator.AirTemperature} value={batch?.airTemperature} />
              </AntSpace>

              <AntSpace direction="vertical">
                <Indicator
                  type={BatchIndicator.AverageOvoscanAirTemperature}
                  value={batch?.averageOvoscanAirTemperature}
                />
                <Indicator type={BatchIndicator.CO2} value={batch?.co2} />
              </AntSpace>

              <AntSpace direction="vertical">
                {hatchedPercentage < 100 && (
                  <>
                    <Indicator
                      type={BatchIndicator.AverageEggTemperature}
                      value={batch?.averageEggTemperature}
                    />
                    <Indicator type={BatchIndicator.Humidity} value={batch?.humidity} />
                  </>
                )}
              </AntSpace>
            </AntFlex>
          </AntSpace>
        </AntCard>
      )}

      {isClosed ? (
        <CloseDetails batch={batch} />
      ) : (
        <AntFlex className={styles.ovoscansContainer}>
          <AntFlex className={styles.ovoscansSubsetContainer}>
            {getSensors(batch).slice(0, 4)}
          </AntFlex>
          <AntFlex className={styles.ovoscansSubsetContainer}>{getSensors(batch).slice(4)}</AntFlex>
        </AntFlex>
      )}

      <AntCard className={styles.container}>
        <AntFlex vertical justify="center">
          <AntFlex justify="space-between">
            <Text strong style={{ fontSize: fontSize.xl }}>
              {t('batch.floor_plan')}
            </Text>

            {!isClosed && isAdmin && <EditButton onClick={toggleIsPlanOpen} />}
          </AntFlex>

          {batch && (
            <Image
              src={getFloorPlanSrc(batch.floorPlan)}
              preview={false}
              className={styles.floorPlan}
              wrapperClassName={styles.floorPlanContainer}
            />
          )}
        </AntFlex>
      </AntCard>

      <EditBatchPlanDrawer
        open={isPlanOpen}
        toggleIsOpen={toggleIsPlanOpen}
        initialValues={{ floorPlan: batch?.floorPlan }}
        onSubmit={onSubmit}
        isPending={isPending}
      />
    </AntSpace>
  );
};
