/* tslint:disable */
/* eslint-disable */
/**
 * NestBorn
 * API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AlarmEntityView
 */
export interface AlarmEntityView {
    /**
     * 
     * @type {string}
     * @memberof AlarmEntityView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AlarmEntityView
     */
    'type': AlarmEntityViewTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AlarmEntityView
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof AlarmEntityView
     */
    'value': number;
    /**
     * 
     * @type {string}
     * @memberof AlarmEntityView
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlarmEntityView
     */
    'closedAt'?: string;
}

export const AlarmEntityViewTypeEnum = {
    Ovo: 'ovo'
} as const;

export type AlarmEntityViewTypeEnum = typeof AlarmEntityViewTypeEnum[keyof typeof AlarmEntityViewTypeEnum];

/**
 * 
 * @export
 * @interface AlarmPayloadView
 */
export interface AlarmPayloadView {
    /**
     * 
     * @type {string}
     * @memberof AlarmPayloadView
     */
    'type': AlarmPayloadViewTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AlarmPayloadView
     */
    'status'?: AlarmPayloadViewStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AlarmPayloadView
     */
    'closedAt'?: string;
    /**
     * 
     * @type {Array<AlarmEntityView>}
     * @memberof AlarmPayloadView
     */
    'entities'?: Array<AlarmEntityView>;
}

export const AlarmPayloadViewTypeEnum = {
    NoData: 'no_data',
    AirTempTooHot: 'air_temp_too_hot',
    AirTempTooCold: 'air_temp_too_cold',
    Co2TooHigh: 'co2_too_high'
} as const;

export type AlarmPayloadViewTypeEnum = typeof AlarmPayloadViewTypeEnum[keyof typeof AlarmPayloadViewTypeEnum];
export const AlarmPayloadViewStatusEnum = {
    Open: 'open',
    Closed: 'closed'
} as const;

export type AlarmPayloadViewStatusEnum = typeof AlarmPayloadViewStatusEnum[keyof typeof AlarmPayloadViewStatusEnum];

/**
 * 
 * @export
 * @interface BatchBody
 */
export interface BatchBody {
    /**
     * 
     * @type {string}
     * @memberof BatchBody
     */
    'hatcheryId': string;
    /**
     * 
     * @type {string}
     * @memberof BatchBody
     */
    'houseId': string;
    /**
     * 
     * @type {string}
     * @memberof BatchBody
     */
    'breedId': string;
    /**
     * 
     * @type {string}
     * @memberof BatchBody
     */
    'sensorSetId': string;
    /**
     * 
     * @type {string}
     * @memberof BatchBody
     */
    'monitoringStart': string;
    /**
     * 
     * @type {string}
     * @memberof BatchBody
     */
    'monitoringEnd': string;
    /**
     * 
     * @type {number}
     * @memberof BatchBody
     */
    'numberPlaced': number;
    /**
     * 
     * @type {number}
     * @memberof BatchBody
     */
    'expectedNonHatched'?: number;
    /**
     * 
     * @type {number}
     * @memberof BatchBody
     */
    'averageParentAge'?: number;
    /**
     * 
     * @type {string}
     * @memberof BatchBody
     */
    'parentFlockId'?: string;
    /**
     * 
     * @type {number}
     * @memberof BatchBody
     */
    'floorPlan': number;
}
/**
 * 
 * @export
 * @interface BatchCustomerLocationView
 */
export interface BatchCustomerLocationView {
    /**
     * 
     * @type {string}
     * @memberof BatchCustomerLocationView
     */
    'name'?: string;
    /**
     * 
     * @type {LocationView}
     * @memberof BatchCustomerLocationView
     */
    'location': LocationView;
    /**
     * 
     * @type {BatchCustomerView}
     * @memberof BatchCustomerLocationView
     */
    'customer'?: BatchCustomerView;
    /**
     * 
     * @type {string}
     * @memberof BatchCustomerLocationView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BatchCustomerLocationView
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof BatchCustomerLocationView
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface BatchCustomerView
 */
export interface BatchCustomerView {
    /**
     * 
     * @type {string}
     * @memberof BatchCustomerView
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BatchCustomerView
     */
    'contactPerson'?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchCustomerView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BatchCustomerView
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof BatchCustomerView
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface BatchDetailView
 */
export interface BatchDetailView {
    /**
     * 
     * @type {BatchHatcheryView}
     * @memberof BatchDetailView
     */
    'hatchery'?: BatchHatcheryView;
    /**
     * 
     * @type {BatchHouseView}
     * @memberof BatchDetailView
     */
    'house'?: BatchHouseView;
    /**
     * 
     * @type {BreedView}
     * @memberof BatchDetailView
     */
    'breed'?: BreedView;
    /**
     * 
     * @type {BatchSensorSetView}
     * @memberof BatchDetailView
     */
    'sensorSet'?: BatchSensorSetView;
    /**
     * 
     * @type {string}
     * @memberof BatchDetailView
     */
    'monitoringStart': string;
    /**
     * 
     * @type {string}
     * @memberof BatchDetailView
     */
    'monitoringEnd': string;
    /**
     * 
     * @type {number}
     * @memberof BatchDetailView
     */
    'numberPlaced': number;
    /**
     * 
     * @type {number}
     * @memberof BatchDetailView
     */
    'expectedNonHatched'?: number;
    /**
     * 
     * @type {number}
     * @memberof BatchDetailView
     */
    'averageParentAge'?: number;
    /**
     * 
     * @type {string}
     * @memberof BatchDetailView
     */
    'parentFlockId'?: string;
    /**
     * 
     * @type {number}
     * @memberof BatchDetailView
     */
    'floorPlan': number;
    /**
     * 
     * @type {number}
     * @memberof BatchDetailView
     */
    'numberNonHatched'?: number;
    /**
     * 
     * @type {number}
     * @memberof BatchDetailView
     */
    'selectionDay1'?: number;
    /**
     * 
     * @type {number}
     * @memberof BatchDetailView
     */
    'selectionDay7'?: number;
    /**
     * 
     * @type {number}
     * @memberof BatchDetailView
     */
    'mortalityDay7'?: number;
    /**
     * 
     * @type {string}
     * @memberof BatchDetailView
     */
    'conclusion'?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchDetailView
     */
    'status': BatchDetailViewStatusEnum;
    /**
     * 
     * @type {Array<BatchSensorView>}
     * @memberof BatchDetailView
     */
    'sensors': Array<BatchSensorView>;
    /**
     * 
     * @type {number}
     * @memberof BatchDetailView
     */
    'averageEggTemperature'?: number;
    /**
     * 
     * @type {number}
     * @memberof BatchDetailView
     */
    'averageOvoscanAirTemperature'?: number;
    /**
     * 
     * @type {number}
     * @memberof BatchDetailView
     */
    'hatchedPercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof BatchDetailView
     */
    'humidity'?: number;
    /**
     * 
     * @type {number}
     * @memberof BatchDetailView
     */
    'co2'?: number;
    /**
     * 
     * @type {number}
     * @memberof BatchDetailView
     */
    'airTemperature'?: number;
    /**
     * 
     * @type {string}
     * @memberof BatchDetailView
     */
    'maxMeasurementDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BatchDetailView
     */
    'avgEggShellTemperature'?: number;
    /**
     * 
     * @type {number}
     * @memberof BatchDetailView
     */
    'avgHumidity'?: number;
    /**
     * 
     * @type {number}
     * @memberof BatchDetailView
     */
    'avgCo2Phase1'?: number;
    /**
     * 
     * @type {number}
     * @memberof BatchDetailView
     */
    'avgCo2Phase2'?: number;
    /**
     * 
     * @type {string}
     * @memberof BatchDetailView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BatchDetailView
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof BatchDetailView
     */
    'updatedAt'?: string;
}

export const BatchDetailViewStatusEnum = {
    Inactive: 'inactive',
    Active: 'active',
    Closed: 'closed'
} as const;

export type BatchDetailViewStatusEnum = typeof BatchDetailViewStatusEnum[keyof typeof BatchDetailViewStatusEnum];

/**
 * 
 * @export
 * @interface BatchEventView
 */
export interface BatchEventView {
    /**
     * 
     * @type {string}
     * @memberof BatchEventView
     */
    'type': BatchEventViewTypeEnum;
    /**
     * 
     * @type {UserView}
     * @memberof BatchEventView
     */
    'user'?: UserView;
    /**
     * 
     * @type {string}
     * @memberof BatchEventView
     */
    'text'?: string;
    /**
     * 
     * @type {AlarmPayloadView}
     * @memberof BatchEventView
     */
    'alarmPayload'?: AlarmPayloadView;
    /**
     * 
     * @type {string}
     * @memberof BatchEventView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BatchEventView
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof BatchEventView
     */
    'updatedAt'?: string;
}

export const BatchEventViewTypeEnum = {
    Alarm: 'alarm',
    Comment: 'comment',
    Closure: 'closure'
} as const;

export type BatchEventViewTypeEnum = typeof BatchEventViewTypeEnum[keyof typeof BatchEventViewTypeEnum];

/**
 * 
 * @export
 * @interface BatchHatcheryView
 */
export interface BatchHatcheryView {
    /**
     * 
     * @type {string}
     * @memberof BatchHatcheryView
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BatchHatcheryView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BatchHatcheryView
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof BatchHatcheryView
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface BatchHouseView
 */
export interface BatchHouseView {
    /**
     * 
     * @type {string}
     * @memberof BatchHouseView
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BatchHouseView
     */
    'size': string;
    /**
     * 
     * @type {string}
     * @memberof BatchHouseView
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof BatchHouseView
     */
    'lastFloorPlan'?: number;
    /**
     * 
     * @type {string}
     * @memberof BatchHouseView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BatchHouseView
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof BatchHouseView
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {BatchCustomerLocationView}
     * @memberof BatchHouseView
     */
    'customerLocation': BatchCustomerLocationView;
}
/**
 * 
 * @export
 * @interface BatchSensorSetView
 */
export interface BatchSensorSetView {
    /**
     * 
     * @type {string}
     * @memberof BatchSensorSetView
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BatchSensorSetView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BatchSensorSetView
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof BatchSensorSetView
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface BatchSensorView
 */
export interface BatchSensorView {
    /**
     * 
     * @type {number}
     * @memberof BatchSensorView
     */
    'position'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof BatchSensorView
     */
    'online': boolean;
    /**
     * 
     * @type {Array<EggView>}
     * @memberof BatchSensorView
     */
    'eggs': Array<EggView>;
    /**
     * 
     * @type {string}
     * @memberof BatchSensorView
     */
    'airTemperatureStatus': BatchSensorViewAirTemperatureStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof BatchSensorView
     */
    'airTemperature'?: number;
    /**
     * 
     * @type {string}
     * @memberof BatchSensorView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BatchSensorView
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof BatchSensorView
     */
    'updatedAt'?: string;
}

export const BatchSensorViewAirTemperatureStatusEnum = {
    Unknown: 'unknown',
    WayTooCold: 'wayTooCold',
    TooCold: 'tooCold',
    Normal: 'normal',
    TooHot: 'tooHot',
    WayTooHot: 'wayTooHot'
} as const;

export type BatchSensorViewAirTemperatureStatusEnum = typeof BatchSensorViewAirTemperatureStatusEnum[keyof typeof BatchSensorViewAirTemperatureStatusEnum];

/**
 * 
 * @export
 * @interface BatchStatsView
 */
export interface BatchStatsView {
    /**
     * 
     * @type {Array<StatView>}
     * @memberof BatchStatsView
     */
    'external': Array<StatView>;
    /**
     * 
     * @type {Array<StatView>}
     * @memberof BatchStatsView
     */
    'ovo': Array<StatView>;
    /**
     * 
     * @type {Array<EggHatchedView>}
     * @memberof BatchStatsView
     */
    'hatched': Array<EggHatchedView>;
}
/**
 * 
 * @export
 * @interface BatchView
 */
export interface BatchView {
    /**
     * 
     * @type {BatchHatcheryView}
     * @memberof BatchView
     */
    'hatchery'?: BatchHatcheryView;
    /**
     * 
     * @type {BatchHouseView}
     * @memberof BatchView
     */
    'house'?: BatchHouseView;
    /**
     * 
     * @type {string}
     * @memberof BatchView
     */
    'monitoringStart': string;
    /**
     * 
     * @type {string}
     * @memberof BatchView
     */
    'monitoringEnd': string;
    /**
     * 
     * @type {string}
     * @memberof BatchView
     */
    'status': BatchViewStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof BatchView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BatchView
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof BatchView
     */
    'updatedAt'?: string;
}

export const BatchViewStatusEnum = {
    Inactive: 'inactive',
    Active: 'active',
    Closed: 'closed'
} as const;

export type BatchViewStatusEnum = typeof BatchViewStatusEnum[keyof typeof BatchViewStatusEnum];

/**
 * 
 * @export
 * @interface BreedView
 */
export interface BreedView {
    /**
     * 
     * @type {string}
     * @memberof BreedView
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BreedView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BreedView
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof BreedView
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface CloseBatchBody
 */
export interface CloseBatchBody {
    /**
     * 
     * @type {number}
     * @memberof CloseBatchBody
     */
    'numberPlaced'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CloseBatchBody
     */
    'numberNonHatched'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CloseBatchBody
     */
    'selectionDay1'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CloseBatchBody
     */
    'selectionDay7'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CloseBatchBody
     */
    'mortalityDay7'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CloseBatchBody
     */
    'conclusion'?: string;
}
/**
 * 
 * @export
 * @interface CommentBody
 */
export interface CommentBody {
    /**
     * 
     * @type {string}
     * @memberof CommentBody
     */
    'text': string;
}
/**
 * 
 * @export
 * @interface ConfirmInviteBody
 */
export interface ConfirmInviteBody {
    /**
     * 
     * @type {string}
     * @memberof ConfirmInviteBody
     */
    'token': string;
    /**
     * 
     * @type {string}
     * @memberof ConfirmInviteBody
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof ConfirmInviteBody
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof ConfirmInviteBody
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof ConfirmInviteBody
     */
    'mobilePhone': string;
    /**
     * 
     * @type {string}
     * @memberof ConfirmInviteBody
     */
    'fixedPhone'?: string;
}
/**
 * 
 * @export
 * @interface ConfirmUpdateLegacyBoxBody
 */
export interface ConfirmUpdateLegacyBoxBody {
    /**
     * 
     * @type {string}
     * @memberof ConfirmUpdateLegacyBoxBody
     */
    'confirmUpdate': string;
}
/**
 * 
 * @export
 * @interface CustomerBody
 */
export interface CustomerBody {
    /**
     * 
     * @type {string}
     * @memberof CustomerBody
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerBody
     */
    'externalId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomerBody
     */
    'hatcheryIds': Array<string>;
}
/**
 * 
 * @export
 * @interface CustomerDetailView
 */
export interface CustomerDetailView {
    /**
     * 
     * @type {string}
     * @memberof CustomerDetailView
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetailView
     */
    'externalId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetailView
     */
    'contactPerson'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetailView
     */
    'lastActiveBatchAt'?: string;
    /**
     * 
     * @type {Array<CustomerHatcheryView>}
     * @memberof CustomerDetailView
     */
    'hatcheries': Array<CustomerHatcheryView>;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetailView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetailView
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetailView
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {Array<CustomerLocationView>}
     * @memberof CustomerDetailView
     */
    'customerLocations': Array<CustomerLocationView>;
}
/**
 * 
 * @export
 * @interface CustomerHatcheryView
 */
export interface CustomerHatcheryView {
    /**
     * 
     * @type {string}
     * @memberof CustomerHatcheryView
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerHatcheryView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerHatcheryView
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerHatcheryView
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface CustomerHouseBody
 */
export interface CustomerHouseBody {
    /**
     * 
     * @type {string}
     * @memberof CustomerHouseBody
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerHouseBody
     */
    'size': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerHouseBody
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface CustomerLocationBody
 */
export interface CustomerLocationBody {
    /**
     * 
     * @type {string}
     * @memberof CustomerLocationBody
     */
    'name': string;
    /**
     * 
     * @type {LocationBody}
     * @memberof CustomerLocationBody
     */
    'location': LocationBody;
    /**
     * 
     * @type {Array<CustomerHouseBody>}
     * @memberof CustomerLocationBody
     */
    'houses': Array<CustomerHouseBody>;
}
/**
 * 
 * @export
 * @interface CustomerLocationView
 */
export interface CustomerLocationView {
    /**
     * 
     * @type {string}
     * @memberof CustomerLocationView
     */
    'name'?: string;
    /**
     * 
     * @type {LocationView}
     * @memberof CustomerLocationView
     */
    'location': LocationView;
    /**
     * 
     * @type {Array<HouseView>}
     * @memberof CustomerLocationView
     */
    'houses': Array<HouseView>;
    /**
     * 
     * @type {string}
     * @memberof CustomerLocationView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerLocationView
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerLocationView
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface CustomerView
 */
export interface CustomerView {
    /**
     * 
     * @type {string}
     * @memberof CustomerView
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerView
     */
    'externalId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerView
     */
    'contactPerson'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerView
     */
    'lastActiveBatchAt'?: string;
    /**
     * 
     * @type {Array<CustomerHatcheryView>}
     * @memberof CustomerView
     */
    'hatcheries': Array<CustomerHatcheryView>;
    /**
     * 
     * @type {string}
     * @memberof CustomerView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerView
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerView
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface EggHatchedView
 */
export interface EggHatchedView {
    /**
     * 
     * @type {string}
     * @memberof EggHatchedView
     */
    'time': string;
    /**
     * 
     * @type {number}
     * @memberof EggHatchedView
     */
    'percentage': number;
}
/**
 * 
 * @export
 * @interface EggView
 */
export interface EggView {
    /**
     * 
     * @type {number}
     * @memberof EggView
     */
    'position': number;
    /**
     * 
     * @type {number}
     * @memberof EggView
     */
    'temperature'?: number;
    /**
     * 
     * @type {string}
     * @memberof EggView
     */
    'status': EggViewStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof EggView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof EggView
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof EggView
     */
    'updatedAt'?: string;
}

export const EggViewStatusEnum = {
    Unknown: 'unknown',
    TooCold: 'tooCold',
    Normal: 'normal',
    TooHot: 'tooHot',
    Hatched: 'hatched'
} as const;

export type EggViewStatusEnum = typeof EggViewStatusEnum[keyof typeof EggViewStatusEnum];

/**
 * 
 * @export
 * @interface ForgotPasswordBody
 */
export interface ForgotPasswordBody {
    /**
     * 
     * @type {string}
     * @memberof ForgotPasswordBody
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface GatewayView
 */
export interface GatewayView {
    /**
     * 
     * @type {string}
     * @memberof GatewayView
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GatewayView
     */
    'version'?: string;
    /**
     * 
     * @type {string}
     * @memberof GatewayView
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof GatewayView
     */
    'serialNumber': string;
    /**
     * 
     * @type {string}
     * @memberof GatewayView
     */
    'vendor': string;
    /**
     * 
     * @type {Array<CustomerHatcheryView>}
     * @memberof GatewayView
     */
    'hatcheries': Array<CustomerHatcheryView>;
    /**
     * 
     * @type {string}
     * @memberof GatewayView
     */
    'lastOnline'?: string;
    /**
     * 
     * @type {string}
     * @memberof GatewayView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GatewayView
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof GatewayView
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface HatcheryBody
 */
export interface HatcheryBody {
    /**
     * 
     * @type {LocationBody}
     * @memberof HatcheryBody
     */
    'location': LocationBody;
    /**
     * 
     * @type {string}
     * @memberof HatcheryBody
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface HatcheryView
 */
export interface HatcheryView {
    /**
     * 
     * @type {string}
     * @memberof HatcheryView
     */
    'name': string;
    /**
     * 
     * @type {LocationView}
     * @memberof HatcheryView
     */
    'location': LocationView;
    /**
     * 
     * @type {string}
     * @memberof HatcheryView
     */
    'contactPerson'?: string;
    /**
     * 
     * @type {string}
     * @memberof HatcheryView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof HatcheryView
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof HatcheryView
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface HouseBody
 */
export interface HouseBody {
    /**
     * 
     * @type {string}
     * @memberof HouseBody
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof HouseBody
     */
    'size': string;
    /**
     * 
     * @type {string}
     * @memberof HouseBody
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface HouseView
 */
export interface HouseView {
    /**
     * 
     * @type {string}
     * @memberof HouseView
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof HouseView
     */
    'size': string;
    /**
     * 
     * @type {string}
     * @memberof HouseView
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof HouseView
     */
    'lastFloorPlan'?: number;
    /**
     * 
     * @type {string}
     * @memberof HouseView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof HouseView
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof HouseView
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface InviteBody
 */
export interface InviteBody {
    /**
     * 
     * @type {RoleType}
     * @memberof InviteBody
     */
    'type': RoleType;
    /**
     * 
     * @type {Language}
     * @memberof InviteBody
     */
    'language'?: Language;
    /**
     * 
     * @type {string}
     * @memberof InviteBody
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof InviteBody
     */
    'hatcheryId'?: string;
    /**
     * 
     * @type {string}
     * @memberof InviteBody
     */
    'customerId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InviteBody
     */
    'isContactPerson'?: boolean;
}


/**
 * 
 * @export
 * @interface KpiUserView
 */
export interface KpiUserView {
    /**
     * 
     * @type {number}
     * @memberof KpiUserView
     */
    'totalCustomers'?: number;
    /**
     * 
     * @type {number}
     * @memberof KpiUserView
     */
    'totalHatcheries'?: number;
    /**
     * 
     * @type {number}
     * @memberof KpiUserView
     */
    'totalBatches'?: number;
    /**
     * 
     * @type {number}
     * @memberof KpiUserView
     */
    'activeBatches'?: number;
    /**
     * 
     * @type {number}
     * @memberof KpiUserView
     */
    'activeCustomers'?: number;
    /**
     * 
     * @type {number}
     * @memberof KpiUserView
     */
    'recentAlarms'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Language = {
    Nl: 'nl',
    En: 'en',
    Fr: 'fr'
} as const;

export type Language = typeof Language[keyof typeof Language];


/**
 * 
 * @export
 * @interface LatestBatchEventView
 */
export interface LatestBatchEventView {
    /**
     * 
     * @type {BatchView}
     * @memberof LatestBatchEventView
     */
    'batch'?: BatchView;
    /**
     * 
     * @type {string}
     * @memberof LatestBatchEventView
     */
    'type': LatestBatchEventViewTypeEnum;
    /**
     * 
     * @type {UserView}
     * @memberof LatestBatchEventView
     */
    'user'?: UserView;
    /**
     * 
     * @type {string}
     * @memberof LatestBatchEventView
     */
    'text'?: string;
    /**
     * 
     * @type {AlarmPayloadView}
     * @memberof LatestBatchEventView
     */
    'alarmPayload'?: AlarmPayloadView;
    /**
     * 
     * @type {string}
     * @memberof LatestBatchEventView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof LatestBatchEventView
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof LatestBatchEventView
     */
    'updatedAt'?: string;
}

export const LatestBatchEventViewTypeEnum = {
    Alarm: 'alarm',
    Comment: 'comment',
    Closure: 'closure'
} as const;

export type LatestBatchEventViewTypeEnum = typeof LatestBatchEventViewTypeEnum[keyof typeof LatestBatchEventViewTypeEnum];

/**
 * 
 * @export
 * @interface ListBatchEventView
 */
export interface ListBatchEventView {
    /**
     * 
     * @type {number}
     * @memberof ListBatchEventView
     */
    'count': number;
    /**
     * 
     * @type {Array<BatchEventView>}
     * @memberof ListBatchEventView
     */
    'items': Array<BatchEventView>;
}
/**
 * 
 * @export
 * @interface ListBatchView
 */
export interface ListBatchView {
    /**
     * 
     * @type {number}
     * @memberof ListBatchView
     */
    'count': number;
    /**
     * 
     * @type {Array<BatchView>}
     * @memberof ListBatchView
     */
    'items': Array<BatchView>;
}
/**
 * 
 * @export
 * @interface ListBreedView
 */
export interface ListBreedView {
    /**
     * 
     * @type {number}
     * @memberof ListBreedView
     */
    'count': number;
    /**
     * 
     * @type {Array<BreedView>}
     * @memberof ListBreedView
     */
    'items': Array<BreedView>;
}
/**
 * 
 * @export
 * @interface ListCustomerView
 */
export interface ListCustomerView {
    /**
     * 
     * @type {number}
     * @memberof ListCustomerView
     */
    'count': number;
    /**
     * 
     * @type {Array<CustomerView>}
     * @memberof ListCustomerView
     */
    'items': Array<CustomerView>;
}
/**
 * 
 * @export
 * @interface ListGatewayView
 */
export interface ListGatewayView {
    /**
     * 
     * @type {number}
     * @memberof ListGatewayView
     */
    'count': number;
    /**
     * 
     * @type {Array<GatewayView>}
     * @memberof ListGatewayView
     */
    'items': Array<GatewayView>;
}
/**
 * 
 * @export
 * @interface ListHatcheryView
 */
export interface ListHatcheryView {
    /**
     * 
     * @type {number}
     * @memberof ListHatcheryView
     */
    'count': number;
    /**
     * 
     * @type {Array<HatcheryView>}
     * @memberof ListHatcheryView
     */
    'items': Array<HatcheryView>;
}
/**
 * 
 * @export
 * @interface ListLatestBatchEventView
 */
export interface ListLatestBatchEventView {
    /**
     * 
     * @type {number}
     * @memberof ListLatestBatchEventView
     */
    'count': number;
    /**
     * 
     * @type {Array<LatestBatchEventView>}
     * @memberof ListLatestBatchEventView
     */
    'items': Array<LatestBatchEventView>;
}
/**
 * 
 * @export
 * @interface ListRoleView
 */
export interface ListRoleView {
    /**
     * 
     * @type {number}
     * @memberof ListRoleView
     */
    'count': number;
    /**
     * 
     * @type {Array<RoleView>}
     * @memberof ListRoleView
     */
    'items': Array<RoleView>;
}
/**
 * 
 * @export
 * @interface ListSensorSetView
 */
export interface ListSensorSetView {
    /**
     * 
     * @type {number}
     * @memberof ListSensorSetView
     */
    'count': number;
    /**
     * 
     * @type {Array<SensorSetView>}
     * @memberof ListSensorSetView
     */
    'items': Array<SensorSetView>;
}
/**
 * 
 * @export
 * @interface ListSensorView
 */
export interface ListSensorView {
    /**
     * 
     * @type {number}
     * @memberof ListSensorView
     */
    'count': number;
    /**
     * 
     * @type {Array<SensorView>}
     * @memberof ListSensorView
     */
    'items': Array<SensorView>;
}
/**
 * 
 * @export
 * @interface ListSubscriptionRoleView
 */
export interface ListSubscriptionRoleView {
    /**
     * 
     * @type {number}
     * @memberof ListSubscriptionRoleView
     */
    'count': number;
    /**
     * 
     * @type {Array<SubscriptionRoleView>}
     * @memberof ListSubscriptionRoleView
     */
    'items': Array<SubscriptionRoleView>;
}
/**
 * 
 * @export
 * @interface ListUserView
 */
export interface ListUserView {
    /**
     * 
     * @type {number}
     * @memberof ListUserView
     */
    'count': number;
    /**
     * 
     * @type {Array<UserView>}
     * @memberof ListUserView
     */
    'items': Array<UserView>;
}
/**
 * 
 * @export
 * @interface LocationBody
 */
export interface LocationBody {
    /**
     * 
     * @type {string}
     * @memberof LocationBody
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof LocationBody
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof LocationBody
     */
    'postalCode': string;
    /**
     * 
     * @type {string}
     * @memberof LocationBody
     */
    'country': string;
}
/**
 * 
 * @export
 * @interface LocationView
 */
export interface LocationView {
    /**
     * 
     * @type {string}
     * @memberof LocationView
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof LocationView
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof LocationView
     */
    'postalCode': string;
    /**
     * 
     * @type {string}
     * @memberof LocationView
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof LocationView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof LocationView
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof LocationView
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface LogLegacyBoxBody
 */
export interface LogLegacyBoxBody {
    /**
     * 
     * @type {string}
     * @memberof LogLegacyBoxBody
     */
    'log': string;
}
/**
 * 
 * @export
 * @interface LoginBody
 */
export interface LoginBody {
    /**
     * 
     * @type {string}
     * @memberof LoginBody
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof LoginBody
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface LoginView
 */
export interface LoginView {
    /**
     * 
     * @type {UserView}
     * @memberof LoginView
     */
    'user': UserView;
    /**
     * 
     * @type {string}
     * @memberof LoginView
     */
    'jwtToken': string;
    /**
     * 
     * @type {string}
     * @memberof LoginView
     */
    'refreshToken': string;
}
/**
 * 
 * @export
 * @interface MeasurementLogView
 */
export interface MeasurementLogView {
    /**
     * 
     * @type {string}
     * @memberof MeasurementLogView
     */
    'processedAt': string;
    /**
     * 
     * @type {number}
     * @memberof MeasurementLogView
     */
    'measurements': number;
    /**
     * 
     * @type {string}
     * @memberof MeasurementLogView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof MeasurementLogView
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof MeasurementLogView
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const MeasurementSystem = {
    Metric: 'metric',
    Imperial: 'imperial'
} as const;

export type MeasurementSystem = typeof MeasurementSystem[keyof typeof MeasurementSystem];


/**
 * 
 * @export
 * @interface PointView
 */
export interface PointView {
    /**
     * 
     * @type {string}
     * @memberof PointView
     */
    'time': string;
    /**
     * 
     * @type {number}
     * @memberof PointView
     */
    'value': number;
    /**
     * 
     * @type {string}
     * @memberof PointView
     */
    'serialNumber'?: string;
    /**
     * 
     * @type {number}
     * @memberof PointView
     */
    'position'?: number;
}
/**
 * 
 * @export
 * @interface ProcessFileLegacyBoxBody
 */
export interface ProcessFileLegacyBoxBody {
    /**
     * 
     * @type {string}
     * @memberof ProcessFileLegacyBoxBody
     */
    'bucket': string;
    /**
     * 
     * @type {string}
     * @memberof ProcessFileLegacyBoxBody
     */
    'name': string;
    /**
     * 
     * @type {ProcessFileMetadataLegacyBoxBody}
     * @memberof ProcessFileLegacyBoxBody
     */
    'metadata': ProcessFileMetadataLegacyBoxBody;
}
/**
 * 
 * @export
 * @interface ProcessFileMetadataLegacyBoxBody
 */
export interface ProcessFileMetadataLegacyBoxBody {
    /**
     * 
     * @type {string}
     * @memberof ProcessFileMetadataLegacyBoxBody
     */
    'gateway': string;
}
/**
 * 
 * @export
 * @interface RefreshBody
 */
export interface RefreshBody {
    /**
     * 
     * @type {string}
     * @memberof RefreshBody
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface ResponseLegacyBoxView
 */
export interface ResponseLegacyBoxView {
    /**
     * 
     * @type {number}
     * @memberof ResponseLegacyBoxView
     */
    'status_code': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseLegacyBoxView
     */
    'message': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RoleType = {
    NestbornAdmin: 'nestbornAdmin',
    HatcheryAdmin: 'hatcheryAdmin',
    HatcheryUser: 'hatcheryUser',
    CustomerAdmin: 'customerAdmin',
    CustomerUser: 'customerUser'
} as const;

export type RoleType = typeof RoleType[keyof typeof RoleType];


/**
 * 
 * @export
 * @interface RoleView
 */
export interface RoleView {
    /**
     * 
     * @type {UserView}
     * @memberof RoleView
     */
    'user': UserView;
    /**
     * 
     * @type {string}
     * @memberof RoleView
     */
    'type': RoleViewTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof RoleView
     */
    'isContactPerson'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RoleView
     */
    'status'?: RoleViewStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof RoleView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RoleView
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof RoleView
     */
    'updatedAt'?: string;
}

export const RoleViewTypeEnum = {
    NestbornAdmin: 'nestbornAdmin',
    HatcheryAdmin: 'hatcheryAdmin',
    HatcheryUser: 'hatcheryUser',
    CustomerAdmin: 'customerAdmin',
    CustomerUser: 'customerUser'
} as const;

export type RoleViewTypeEnum = typeof RoleViewTypeEnum[keyof typeof RoleViewTypeEnum];
export const RoleViewStatusEnum = {
    Pending: 'pending',
    Accepted: 'accepted',
    Declined: 'declined'
} as const;

export type RoleViewStatusEnum = typeof RoleViewStatusEnum[keyof typeof RoleViewStatusEnum];

/**
 * 
 * @export
 * @interface SendBatchBody
 */
export interface SendBatchBody {
    /**
     * 
     * @type {string}
     * @memberof SendBatchBody
     */
    'batchId': string;
}
/**
 * 
 * @export
 * @interface SensorSetBody
 */
export interface SensorSetBody {
    /**
     * 
     * @type {string}
     * @memberof SensorSetBody
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SensorSetBody
     */
    'hatcheryIds': Array<string>;
}
/**
 * 
 * @export
 * @interface SensorSetView
 */
export interface SensorSetView {
    /**
     * 
     * @type {string}
     * @memberof SensorSetView
     */
    'name': string;
    /**
     * 
     * @type {Array<CustomerHatcheryView>}
     * @memberof SensorSetView
     */
    'hatcheries': Array<CustomerHatcheryView>;
    /**
     * 
     * @type {string}
     * @memberof SensorSetView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SensorSetView
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof SensorSetView
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface SensorView
 */
export interface SensorView {
    /**
     * 
     * @type {string}
     * @memberof SensorView
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SensorView
     */
    'serialNumber': string;
    /**
     * 
     * @type {string}
     * @memberof SensorView
     */
    'type': SensorViewTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof SensorView
     */
    'position'?: number;
    /**
     * 
     * @type {SensorSetView}
     * @memberof SensorView
     */
    'sensorSet'?: SensorSetView;
    /**
     * 
     * @type {string}
     * @memberof SensorView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SensorView
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof SensorView
     */
    'updatedAt'?: string;
}

export const SensorViewTypeEnum = {
    Ovoscan: 'ovoscan',
    External: 'external'
} as const;

export type SensorViewTypeEnum = typeof SensorViewTypeEnum[keyof typeof SensorViewTypeEnum];

/**
 * 
 * @export
 * @interface SetPasswordBody
 */
export interface SetPasswordBody {
    /**
     * 
     * @type {string}
     * @memberof SetPasswordBody
     */
    'token': string;
    /**
     * 
     * @type {string}
     * @memberof SetPasswordBody
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface StatView
 */
export interface StatView {
    /**
     * 
     * @type {Array<PointView>}
     * @memberof StatView
     */
    'points': Array<PointView>;
    /**
     * 
     * @type {string}
     * @memberof StatView
     */
    'type': StatViewTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof StatView
     */
    'groupedByEgg'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StatView
     */
    'serialNumber'?: string;
    /**
     * 
     * @type {number}
     * @memberof StatView
     */
    'position'?: number;
}

export const StatViewTypeEnum = {
    Co2: 'co2',
    Humidity: 'humidity',
    AirTemperature: 'airTemperature',
    EggShellTemperature: 'eggShellTemperature'
} as const;

export type StatViewTypeEnum = typeof StatViewTypeEnum[keyof typeof StatViewTypeEnum];

/**
 * 
 * @export
 * @interface SubscriptionBody
 */
export interface SubscriptionBody {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionBody
     */
    'roleId': string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionBody
     */
    'customerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionBody
     */
    'hatcheryId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SubscriptionBody
     */
    'types': Array<SubscriptionBodyTypesEnum>;
}

export const SubscriptionBodyTypesEnum = {
    Notifications: 'notifications',
    BatchReports: 'batchReports'
} as const;

export type SubscriptionBodyTypesEnum = typeof SubscriptionBodyTypesEnum[keyof typeof SubscriptionBodyTypesEnum];

/**
 * 
 * @export
 * @interface SubscriptionRoleView
 */
export interface SubscriptionRoleView {
    /**
     * 
     * @type {Array<SubscriptionView>}
     * @memberof SubscriptionRoleView
     */
    'subscriptions': Array<SubscriptionView>;
    /**
     * 
     * @type {UserView}
     * @memberof SubscriptionRoleView
     */
    'user': UserView;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionRoleView
     */
    'type': SubscriptionRoleViewTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionRoleView
     */
    'isContactPerson'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionRoleView
     */
    'status'?: SubscriptionRoleViewStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionRoleView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionRoleView
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionRoleView
     */
    'updatedAt'?: string;
}

export const SubscriptionRoleViewTypeEnum = {
    NestbornAdmin: 'nestbornAdmin',
    HatcheryAdmin: 'hatcheryAdmin',
    HatcheryUser: 'hatcheryUser',
    CustomerAdmin: 'customerAdmin',
    CustomerUser: 'customerUser'
} as const;

export type SubscriptionRoleViewTypeEnum = typeof SubscriptionRoleViewTypeEnum[keyof typeof SubscriptionRoleViewTypeEnum];
export const SubscriptionRoleViewStatusEnum = {
    Pending: 'pending',
    Accepted: 'accepted',
    Declined: 'declined'
} as const;

export type SubscriptionRoleViewStatusEnum = typeof SubscriptionRoleViewStatusEnum[keyof typeof SubscriptionRoleViewStatusEnum];

/**
 * 
 * @export
 * @interface SubscriptionView
 */
export interface SubscriptionView {
    /**
     * 
     * @type {Array<string>}
     * @memberof SubscriptionView
     */
    'types': Array<SubscriptionViewTypesEnum>;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionView
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionView
     */
    'updatedAt'?: string;
}

export const SubscriptionViewTypesEnum = {
    Notifications: 'notifications',
    BatchReports: 'batchReports'
} as const;

export type SubscriptionViewTypesEnum = typeof SubscriptionViewTypesEnum[keyof typeof SubscriptionViewTypesEnum];

/**
 * 
 * @export
 * @enum {string}
 */

export const TemperatureScale = {
    Celsius: 'celsius',
    Fahrenheit: 'fahrenheit'
} as const;

export type TemperatureScale = typeof TemperatureScale[keyof typeof TemperatureScale];


/**
 * 
 * @export
 * @interface UpdateBatchBody
 */
export interface UpdateBatchBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateBatchBody
     */
    'breedId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBatchBody
     */
    'sensorSetId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBatchBody
     */
    'monitoringStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBatchBody
     */
    'monitoringEnd'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateBatchBody
     */
    'numberPlaced'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateBatchBody
     */
    'expectedNonHatched'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateBatchBody
     */
    'averageParentAge'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateBatchBody
     */
    'parentFlockId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateBatchBody
     */
    'floorPlan'?: number;
}
/**
 * 
 * @export
 * @interface UpdateCustomerBody
 */
export interface UpdateCustomerBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerBody
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerBody
     */
    'externalId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateCustomerBody
     */
    'hatcheryIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateCustomerLocationBody
 */
export interface UpdateCustomerLocationBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerLocationBody
     */
    'name'?: string;
    /**
     * 
     * @type {UpdateLocationBody}
     * @memberof UpdateCustomerLocationBody
     */
    'location'?: UpdateLocationBody;
}
/**
 * 
 * @export
 * @interface UpdateGatewayBody
 */
export interface UpdateGatewayBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateGatewayBody
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateGatewayBody
     */
    'code'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateGatewayBody
     */
    'hatcheryIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateHatcheryBody
 */
export interface UpdateHatcheryBody {
    /**
     * 
     * @type {UpdateLocationBody}
     * @memberof UpdateHatcheryBody
     */
    'location'?: UpdateLocationBody;
    /**
     * 
     * @type {string}
     * @memberof UpdateHatcheryBody
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface UpdateHouseBody
 */
export interface UpdateHouseBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateHouseBody
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateHouseBody
     */
    'size'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateHouseBody
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface UpdateLegacyBoxBody
 */
export interface UpdateLegacyBoxBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateLegacyBoxBody
     */
    'updateInfo': string;
}
/**
 * 
 * @export
 * @interface UpdateLocationBody
 */
export interface UpdateLocationBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateLocationBody
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLocationBody
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLocationBody
     */
    'postalCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLocationBody
     */
    'country'?: string;
}
/**
 * 
 * @export
 * @interface UpdateResponseLegacyBoxView
 */
export interface UpdateResponseLegacyBoxView {
    /**
     * 
     * @type {number}
     * @memberof UpdateResponseLegacyBoxView
     */
    'status_code': number;
    /**
     * 
     * @type {string}
     * @memberof UpdateResponseLegacyBoxView
     */
    'message': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateResponseLegacyBoxView
     */
    'update_available': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateResponseLegacyBoxView
     */
    'filename': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateResponseLegacyBoxView
     */
    'version': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateResponseLegacyBoxView
     */
    'update_pack': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateResponseLegacyBoxView
     */
    'checksum': string;
}
/**
 * 
 * @export
 * @interface UpdateRoleBody
 */
export interface UpdateRoleBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateRoleBody
     */
    'type'?: UpdateRoleBodyTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateRoleBody
     */
    'isContactPerson'?: boolean;
}

export const UpdateRoleBodyTypeEnum = {
    NestbornAdmin: 'nestbornAdmin',
    HatcheryAdmin: 'hatcheryAdmin',
    HatcheryUser: 'hatcheryUser',
    CustomerAdmin: 'customerAdmin',
    CustomerUser: 'customerUser'
} as const;

export type UpdateRoleBodyTypeEnum = typeof UpdateRoleBodyTypeEnum[keyof typeof UpdateRoleBodyTypeEnum];

/**
 * 
 * @export
 * @interface UpdateSensorBody
 */
export interface UpdateSensorBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateSensorBody
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateSensorBody
     */
    'position'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateSensorBody
     */
    'sensorSetId'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateSensorSetBody
 */
export interface UpdateSensorSetBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateSensorSetBody
     */
    'name'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateSensorSetBody
     */
    'hatcheryIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateSubscriptionBody
 */
export interface UpdateSubscriptionBody {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateSubscriptionBody
     */
    'types': Array<UpdateSubscriptionBodyTypesEnum>;
}

export const UpdateSubscriptionBodyTypesEnum = {
    Notifications: 'notifications',
    BatchReports: 'batchReports'
} as const;

export type UpdateSubscriptionBodyTypesEnum = typeof UpdateSubscriptionBodyTypesEnum[keyof typeof UpdateSubscriptionBodyTypesEnum];

/**
 * 
 * @export
 * @interface UpdateUserBody
 */
export interface UpdateUserBody {
    /**
     * 
     * @type {Language}
     * @memberof UpdateUserBody
     */
    'language'?: Language;
    /**
     * 
     * @type {TemperatureScale}
     * @memberof UpdateUserBody
     */
    'eggTempScale'?: TemperatureScale;
    /**
     * 
     * @type {TemperatureScale}
     * @memberof UpdateUserBody
     */
    'airTempScale'?: TemperatureScale;
    /**
     * 
     * @type {MeasurementSystem}
     * @memberof UpdateUserBody
     */
    'measurementSystem'?: MeasurementSystem;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserBody
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserBody
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserBody
     */
    'mobilePhone'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserBody
     */
    'fixedPhone'?: string;
}


/**
 * 
 * @export
 * @interface UpdateUserPasswordBody
 */
export interface UpdateUserPasswordBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserPasswordBody
     */
    'oldPassword': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserPasswordBody
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface UploadFileLegacyBoxBody
 */
export interface UploadFileLegacyBoxBody {
    /**
     * 
     * @type {string}
     * @memberof UploadFileLegacyBoxBody
     */
    'uploadFile': string;
}
/**
 * 
 * @export
 * @interface UserBody
 */
export interface UserBody {
    /**
     * 
     * @type {string}
     * @memberof UserBody
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UserBody
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof UserBody
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserBody
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface UserView
 */
export interface UserView {
    /**
     * 
     * @type {Language}
     * @memberof UserView
     */
    'language'?: Language;
    /**
     * 
     * @type {TemperatureScale}
     * @memberof UserView
     */
    'eggTempScale'?: TemperatureScale;
    /**
     * 
     * @type {TemperatureScale}
     * @memberof UserView
     */
    'airTempScale'?: TemperatureScale;
    /**
     * 
     * @type {MeasurementSystem}
     * @memberof UserView
     */
    'measurementSystem'?: MeasurementSystem;
    /**
     * 
     * @type {string}
     * @memberof UserView
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserView
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserView
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserView
     */
    'fullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserView
     */
    'mobilePhone'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserView
     */
    'fixedPhone'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserView
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof UserView
     */
    'updatedAt'?: string;
}



/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ForgotPasswordBody} forgotPasswordBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword: async (forgotPasswordBody: ForgotPasswordBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forgotPasswordBody' is not null or undefined
            assertParamExists('forgotPassword', 'forgotPasswordBody', forgotPasswordBody)
            const localVarPath = `/auth/forgot-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forgotPasswordBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LoginBody} loginBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (loginBody: LoginBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginBody' is not null or undefined
            assertParamExists('login', 'loginBody', loginBody)
            const localVarPath = `/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RefreshBody} refreshBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refresh: async (refreshBody: RefreshBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refreshBody' is not null or undefined
            assertParamExists('refresh', 'refreshBody', refreshBody)
            const localVarPath = `/auth/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(refreshBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SetPasswordBody} setPasswordBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPassword: async (setPasswordBody: SetPasswordBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'setPasswordBody' is not null or undefined
            assertParamExists('setPassword', 'setPasswordBody', setPasswordBody)
            const localVarPath = `/auth/set-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setPasswordBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ForgotPasswordBody} forgotPasswordBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forgotPassword(forgotPasswordBody: ForgotPasswordBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forgotPassword(forgotPasswordBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.forgotPassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {LoginBody} loginBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(loginBody: LoginBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(loginBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.login']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {RefreshBody} refreshBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refresh(refreshBody: RefreshBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refresh(refreshBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.refresh']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {SetPasswordBody} setPasswordBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setPassword(setPasswordBody: SetPasswordBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setPassword(setPasswordBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.setPassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @param {AuthApiForgotPasswordRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword(requestParameters: AuthApiForgotPasswordRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forgotPassword(requestParameters.forgotPasswordBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthApiLoginRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(requestParameters: AuthApiLoginRequest, options?: RawAxiosRequestConfig): AxiosPromise<LoginView> {
            return localVarFp.login(requestParameters.loginBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthApiRefreshRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refresh(requestParameters: AuthApiRefreshRequest, options?: RawAxiosRequestConfig): AxiosPromise<LoginView> {
            return localVarFp.refresh(requestParameters.refreshBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthApiSetPasswordRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPassword(requestParameters: AuthApiSetPasswordRequest, options?: RawAxiosRequestConfig): AxiosPromise<LoginView> {
            return localVarFp.setPassword(requestParameters.setPasswordBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for forgotPassword operation in AuthApi.
 * @export
 * @interface AuthApiForgotPasswordRequest
 */
export interface AuthApiForgotPasswordRequest {
    /**
     * 
     * @type {ForgotPasswordBody}
     * @memberof AuthApiForgotPassword
     */
    readonly forgotPasswordBody: ForgotPasswordBody
}

/**
 * Request parameters for login operation in AuthApi.
 * @export
 * @interface AuthApiLoginRequest
 */
export interface AuthApiLoginRequest {
    /**
     * 
     * @type {LoginBody}
     * @memberof AuthApiLogin
     */
    readonly loginBody: LoginBody
}

/**
 * Request parameters for refresh operation in AuthApi.
 * @export
 * @interface AuthApiRefreshRequest
 */
export interface AuthApiRefreshRequest {
    /**
     * 
     * @type {RefreshBody}
     * @memberof AuthApiRefresh
     */
    readonly refreshBody: RefreshBody
}

/**
 * Request parameters for setPassword operation in AuthApi.
 * @export
 * @interface AuthApiSetPasswordRequest
 */
export interface AuthApiSetPasswordRequest {
    /**
     * 
     * @type {SetPasswordBody}
     * @memberof AuthApiSetPassword
     */
    readonly setPasswordBody: SetPasswordBody
}

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @param {AuthApiForgotPasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public forgotPassword(requestParameters: AuthApiForgotPasswordRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).forgotPassword(requestParameters.forgotPasswordBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthApiLoginRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public login(requestParameters: AuthApiLoginRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).login(requestParameters.loginBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthApiRefreshRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public refresh(requestParameters: AuthApiRefreshRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).refresh(requestParameters.refreshBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthApiSetPasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public setPassword(requestParameters: AuthApiSetPasswordRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).setPassword(requestParameters.setPasswordBody, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BatchApi - axios parameter creator
 * @export
 */
export const BatchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {CloseBatchBody} closeBatchBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        closeBatch: async (id: string, closeBatchBody: CloseBatchBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('closeBatch', 'id', id)
            // verify required parameter 'closeBatchBody' is not null or undefined
            assertParamExists('closeBatch', 'closeBatchBody', closeBatchBody)
            const localVarPath = `/batches/{id}/close`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(closeBatchBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BatchBody} batchBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBatch: async (batchBody: BatchBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'batchBody' is not null or undefined
            assertParamExists('createBatch', 'batchBody', batchBody)
            const localVarPath = `/batches`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(batchBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {CommentBody} commentBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBatchComment: async (id: string, commentBody: CommentBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createBatchComment', 'id', id)
            // verify required parameter 'commentBody' is not null or undefined
            assertParamExists('createBatchComment', 'commentBody', commentBody)
            const localVarPath = `/batches/{id}/comments`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(commentBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBatch: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteBatch', 'id', id)
            const localVarPath = `/batches/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBatchById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBatchById', 'id', id)
            const localVarPath = `/batches/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBatchDeliveryDocument: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBatchDeliveryDocument', 'id', id)
            const localVarPath = `/batches/{id}/delivery-document`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBatchEvents: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBatchEvents', 'id', id)
            const localVarPath = `/batches/{id}/events`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBatchReport: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBatchReport', 'id', id)
            const localVarPath = `/batches/{id}/report`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBatchStats: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBatchStats', 'id', id)
            const localVarPath = `/batches/{id}/stats`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {Array<string>} [customerIds] 
         * @param {Array<string>} [hatcheryIds] 
         * @param {GetBatchesStatusEnum} [status] 
         * @param {boolean} [withData] 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {string} [search] 
         * @param {string} [sort] 
         * @param {GetBatchesDirectionEnum} [direction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBatches: async (limit: number, offset: number, customerIds?: Array<string>, hatcheryIds?: Array<string>, status?: GetBatchesStatusEnum, withData?: boolean, start?: string, end?: string, search?: string, sort?: string, direction?: GetBatchesDirectionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('getBatches', 'limit', limit)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('getBatches', 'offset', offset)
            const localVarPath = `/batches`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerIds) {
                localVarQueryParameter['customerIds'] = customerIds;
            }

            if (hatcheryIds) {
                localVarQueryParameter['hatcheryIds'] = hatcheryIds;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (withData !== undefined) {
                localVarQueryParameter['withData'] = withData;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {Array<string>} [customerIds] 
         * @param {Array<string>} [hatcheryIds] 
         * @param {GetBatchesExportStatusEnum} [status] 
         * @param {boolean} [withData] 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {string} [search] 
         * @param {string} [sort] 
         * @param {GetBatchesExportDirectionEnum} [direction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBatchesExport: async (limit: number, offset: number, customerIds?: Array<string>, hatcheryIds?: Array<string>, status?: GetBatchesExportStatusEnum, withData?: boolean, start?: string, end?: string, search?: string, sort?: string, direction?: GetBatchesExportDirectionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('getBatchesExport', 'limit', limit)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('getBatchesExport', 'offset', offset)
            const localVarPath = `/batches/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerIds) {
                localVarQueryParameter['customerIds'] = customerIds;
            }

            if (hatcheryIds) {
                localVarQueryParameter['hatcheryIds'] = hatcheryIds;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (withData !== undefined) {
                localVarQueryParameter['withData'] = withData;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {string} [sort] 
         * @param {GetBreedsDirectionEnum} [direction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBreeds: async (limit: number, offset: number, sort?: string, direction?: GetBreedsDirectionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('getBreeds', 'limit', limit)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('getBreeds', 'offset', offset)
            const localVarPath = `/batches/breeds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestBatchEvents: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/batches/events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processBatches: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/batches/process`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SendBatchBody} sendBatchBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendBatch: async (sendBatchBody: SendBatchBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendBatchBody' is not null or undefined
            assertParamExists('sendBatch', 'sendBatchBody', sendBatchBody)
            const localVarPath = `/batches/send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendBatchBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateBatchBody} updateBatchBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBatch: async (id: string, updateBatchBody: UpdateBatchBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateBatch', 'id', id)
            // verify required parameter 'updateBatchBody' is not null or undefined
            assertParamExists('updateBatch', 'updateBatchBody', updateBatchBody)
            const localVarPath = `/batches/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBatchBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BatchApi - functional programming interface
 * @export
 */
export const BatchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BatchApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {CloseBatchBody} closeBatchBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async closeBatch(id: string, closeBatchBody: CloseBatchBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BatchDetailView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.closeBatch(id, closeBatchBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BatchApi.closeBatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {BatchBody} batchBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBatch(batchBody: BatchBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BatchView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBatch(batchBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BatchApi.createBatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {CommentBody} commentBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBatchComment(id: string, commentBody: CommentBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BatchEventView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBatchComment(id, commentBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BatchApi.createBatchComment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBatch(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBatch(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BatchApi.deleteBatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBatchById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BatchDetailView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBatchById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BatchApi.getBatchById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBatchDeliveryDocument(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBatchDeliveryDocument(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BatchApi.getBatchDeliveryDocument']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBatchEvents(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListBatchEventView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBatchEvents(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BatchApi.getBatchEvents']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBatchReport(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBatchReport(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BatchApi.getBatchReport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBatchStats(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BatchStatsView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBatchStats(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BatchApi.getBatchStats']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {Array<string>} [customerIds] 
         * @param {Array<string>} [hatcheryIds] 
         * @param {GetBatchesStatusEnum} [status] 
         * @param {boolean} [withData] 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {string} [search] 
         * @param {string} [sort] 
         * @param {GetBatchesDirectionEnum} [direction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBatches(limit: number, offset: number, customerIds?: Array<string>, hatcheryIds?: Array<string>, status?: GetBatchesStatusEnum, withData?: boolean, start?: string, end?: string, search?: string, sort?: string, direction?: GetBatchesDirectionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListBatchView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBatches(limit, offset, customerIds, hatcheryIds, status, withData, start, end, search, sort, direction, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BatchApi.getBatches']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {Array<string>} [customerIds] 
         * @param {Array<string>} [hatcheryIds] 
         * @param {GetBatchesExportStatusEnum} [status] 
         * @param {boolean} [withData] 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {string} [search] 
         * @param {string} [sort] 
         * @param {GetBatchesExportDirectionEnum} [direction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBatchesExport(limit: number, offset: number, customerIds?: Array<string>, hatcheryIds?: Array<string>, status?: GetBatchesExportStatusEnum, withData?: boolean, start?: string, end?: string, search?: string, sort?: string, direction?: GetBatchesExportDirectionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBatchesExport(limit, offset, customerIds, hatcheryIds, status, withData, start, end, search, sort, direction, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BatchApi.getBatchesExport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {string} [sort] 
         * @param {GetBreedsDirectionEnum} [direction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBreeds(limit: number, offset: number, sort?: string, direction?: GetBreedsDirectionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListBreedView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBreeds(limit, offset, sort, direction, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BatchApi.getBreeds']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLatestBatchEvents(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListLatestBatchEventView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLatestBatchEvents(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BatchApi.getLatestBatchEvents']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processBatches(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processBatches(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BatchApi.processBatches']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {SendBatchBody} sendBatchBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendBatch(sendBatchBody: SendBatchBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendBatch(sendBatchBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BatchApi.sendBatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateBatchBody} updateBatchBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBatch(id: string, updateBatchBody: UpdateBatchBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BatchDetailView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBatch(id, updateBatchBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BatchApi.updateBatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BatchApi - factory interface
 * @export
 */
export const BatchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BatchApiFp(configuration)
    return {
        /**
         * 
         * @param {BatchApiCloseBatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        closeBatch(requestParameters: BatchApiCloseBatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<BatchDetailView> {
            return localVarFp.closeBatch(requestParameters.id, requestParameters.closeBatchBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BatchApiCreateBatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBatch(requestParameters: BatchApiCreateBatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<BatchView> {
            return localVarFp.createBatch(requestParameters.batchBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BatchApiCreateBatchCommentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBatchComment(requestParameters: BatchApiCreateBatchCommentRequest, options?: RawAxiosRequestConfig): AxiosPromise<BatchEventView> {
            return localVarFp.createBatchComment(requestParameters.id, requestParameters.commentBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BatchApiDeleteBatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBatch(requestParameters: BatchApiDeleteBatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteBatch(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BatchApiGetBatchByIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBatchById(requestParameters: BatchApiGetBatchByIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<BatchDetailView> {
            return localVarFp.getBatchById(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BatchApiGetBatchDeliveryDocumentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBatchDeliveryDocument(requestParameters: BatchApiGetBatchDeliveryDocumentRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.getBatchDeliveryDocument(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BatchApiGetBatchEventsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBatchEvents(requestParameters: BatchApiGetBatchEventsRequest, options?: RawAxiosRequestConfig): AxiosPromise<ListBatchEventView> {
            return localVarFp.getBatchEvents(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BatchApiGetBatchReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBatchReport(requestParameters: BatchApiGetBatchReportRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.getBatchReport(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BatchApiGetBatchStatsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBatchStats(requestParameters: BatchApiGetBatchStatsRequest, options?: RawAxiosRequestConfig): AxiosPromise<BatchStatsView> {
            return localVarFp.getBatchStats(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BatchApiGetBatchesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBatches(requestParameters: BatchApiGetBatchesRequest, options?: RawAxiosRequestConfig): AxiosPromise<ListBatchView> {
            return localVarFp.getBatches(requestParameters.limit, requestParameters.offset, requestParameters.customerIds, requestParameters.hatcheryIds, requestParameters.status, requestParameters.withData, requestParameters.start, requestParameters.end, requestParameters.search, requestParameters.sort, requestParameters.direction, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BatchApiGetBatchesExportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBatchesExport(requestParameters: BatchApiGetBatchesExportRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.getBatchesExport(requestParameters.limit, requestParameters.offset, requestParameters.customerIds, requestParameters.hatcheryIds, requestParameters.status, requestParameters.withData, requestParameters.start, requestParameters.end, requestParameters.search, requestParameters.sort, requestParameters.direction, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BatchApiGetBreedsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBreeds(requestParameters: BatchApiGetBreedsRequest, options?: RawAxiosRequestConfig): AxiosPromise<ListBreedView> {
            return localVarFp.getBreeds(requestParameters.limit, requestParameters.offset, requestParameters.sort, requestParameters.direction, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestBatchEvents(options?: RawAxiosRequestConfig): AxiosPromise<ListLatestBatchEventView> {
            return localVarFp.getLatestBatchEvents(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processBatches(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.processBatches(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BatchApiSendBatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendBatch(requestParameters: BatchApiSendBatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.sendBatch(requestParameters.sendBatchBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BatchApiUpdateBatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBatch(requestParameters: BatchApiUpdateBatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<BatchDetailView> {
            return localVarFp.updateBatch(requestParameters.id, requestParameters.updateBatchBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for closeBatch operation in BatchApi.
 * @export
 * @interface BatchApiCloseBatchRequest
 */
export interface BatchApiCloseBatchRequest {
    /**
     * 
     * @type {string}
     * @memberof BatchApiCloseBatch
     */
    readonly id: string

    /**
     * 
     * @type {CloseBatchBody}
     * @memberof BatchApiCloseBatch
     */
    readonly closeBatchBody: CloseBatchBody
}

/**
 * Request parameters for createBatch operation in BatchApi.
 * @export
 * @interface BatchApiCreateBatchRequest
 */
export interface BatchApiCreateBatchRequest {
    /**
     * 
     * @type {BatchBody}
     * @memberof BatchApiCreateBatch
     */
    readonly batchBody: BatchBody
}

/**
 * Request parameters for createBatchComment operation in BatchApi.
 * @export
 * @interface BatchApiCreateBatchCommentRequest
 */
export interface BatchApiCreateBatchCommentRequest {
    /**
     * 
     * @type {string}
     * @memberof BatchApiCreateBatchComment
     */
    readonly id: string

    /**
     * 
     * @type {CommentBody}
     * @memberof BatchApiCreateBatchComment
     */
    readonly commentBody: CommentBody
}

/**
 * Request parameters for deleteBatch operation in BatchApi.
 * @export
 * @interface BatchApiDeleteBatchRequest
 */
export interface BatchApiDeleteBatchRequest {
    /**
     * 
     * @type {string}
     * @memberof BatchApiDeleteBatch
     */
    readonly id: string
}

/**
 * Request parameters for getBatchById operation in BatchApi.
 * @export
 * @interface BatchApiGetBatchByIdRequest
 */
export interface BatchApiGetBatchByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof BatchApiGetBatchById
     */
    readonly id: string
}

/**
 * Request parameters for getBatchDeliveryDocument operation in BatchApi.
 * @export
 * @interface BatchApiGetBatchDeliveryDocumentRequest
 */
export interface BatchApiGetBatchDeliveryDocumentRequest {
    /**
     * 
     * @type {string}
     * @memberof BatchApiGetBatchDeliveryDocument
     */
    readonly id: string
}

/**
 * Request parameters for getBatchEvents operation in BatchApi.
 * @export
 * @interface BatchApiGetBatchEventsRequest
 */
export interface BatchApiGetBatchEventsRequest {
    /**
     * 
     * @type {string}
     * @memberof BatchApiGetBatchEvents
     */
    readonly id: string
}

/**
 * Request parameters for getBatchReport operation in BatchApi.
 * @export
 * @interface BatchApiGetBatchReportRequest
 */
export interface BatchApiGetBatchReportRequest {
    /**
     * 
     * @type {string}
     * @memberof BatchApiGetBatchReport
     */
    readonly id: string
}

/**
 * Request parameters for getBatchStats operation in BatchApi.
 * @export
 * @interface BatchApiGetBatchStatsRequest
 */
export interface BatchApiGetBatchStatsRequest {
    /**
     * 
     * @type {string}
     * @memberof BatchApiGetBatchStats
     */
    readonly id: string
}

/**
 * Request parameters for getBatches operation in BatchApi.
 * @export
 * @interface BatchApiGetBatchesRequest
 */
export interface BatchApiGetBatchesRequest {
    /**
     * 
     * @type {number}
     * @memberof BatchApiGetBatches
     */
    readonly limit: number

    /**
     * 
     * @type {number}
     * @memberof BatchApiGetBatches
     */
    readonly offset: number

    /**
     * 
     * @type {Array<string>}
     * @memberof BatchApiGetBatches
     */
    readonly customerIds?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof BatchApiGetBatches
     */
    readonly hatcheryIds?: Array<string>

    /**
     * 
     * @type {'inactive' | 'active' | 'closed'}
     * @memberof BatchApiGetBatches
     */
    readonly status?: GetBatchesStatusEnum

    /**
     * 
     * @type {boolean}
     * @memberof BatchApiGetBatches
     */
    readonly withData?: boolean

    /**
     * 
     * @type {string}
     * @memberof BatchApiGetBatches
     */
    readonly start?: string

    /**
     * 
     * @type {string}
     * @memberof BatchApiGetBatches
     */
    readonly end?: string

    /**
     * 
     * @type {string}
     * @memberof BatchApiGetBatches
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof BatchApiGetBatches
     */
    readonly sort?: string

    /**
     * 
     * @type {'ASC' | 'ASC NULLS LAST' | 'ASC NULLS FIRST' | 'DESC' | 'DESC NULLS LAST' | 'DESC NULLS FIRST' | 'asc' | 'asc nulls last' | 'asc nulls first' | 'desc' | 'desc nulls last' | 'desc nulls first'}
     * @memberof BatchApiGetBatches
     */
    readonly direction?: GetBatchesDirectionEnum
}

/**
 * Request parameters for getBatchesExport operation in BatchApi.
 * @export
 * @interface BatchApiGetBatchesExportRequest
 */
export interface BatchApiGetBatchesExportRequest {
    /**
     * 
     * @type {number}
     * @memberof BatchApiGetBatchesExport
     */
    readonly limit: number

    /**
     * 
     * @type {number}
     * @memberof BatchApiGetBatchesExport
     */
    readonly offset: number

    /**
     * 
     * @type {Array<string>}
     * @memberof BatchApiGetBatchesExport
     */
    readonly customerIds?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof BatchApiGetBatchesExport
     */
    readonly hatcheryIds?: Array<string>

    /**
     * 
     * @type {'inactive' | 'active' | 'closed'}
     * @memberof BatchApiGetBatchesExport
     */
    readonly status?: GetBatchesExportStatusEnum

    /**
     * 
     * @type {boolean}
     * @memberof BatchApiGetBatchesExport
     */
    readonly withData?: boolean

    /**
     * 
     * @type {string}
     * @memberof BatchApiGetBatchesExport
     */
    readonly start?: string

    /**
     * 
     * @type {string}
     * @memberof BatchApiGetBatchesExport
     */
    readonly end?: string

    /**
     * 
     * @type {string}
     * @memberof BatchApiGetBatchesExport
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof BatchApiGetBatchesExport
     */
    readonly sort?: string

    /**
     * 
     * @type {'ASC' | 'ASC NULLS LAST' | 'ASC NULLS FIRST' | 'DESC' | 'DESC NULLS LAST' | 'DESC NULLS FIRST' | 'asc' | 'asc nulls last' | 'asc nulls first' | 'desc' | 'desc nulls last' | 'desc nulls first'}
     * @memberof BatchApiGetBatchesExport
     */
    readonly direction?: GetBatchesExportDirectionEnum
}

/**
 * Request parameters for getBreeds operation in BatchApi.
 * @export
 * @interface BatchApiGetBreedsRequest
 */
export interface BatchApiGetBreedsRequest {
    /**
     * 
     * @type {number}
     * @memberof BatchApiGetBreeds
     */
    readonly limit: number

    /**
     * 
     * @type {number}
     * @memberof BatchApiGetBreeds
     */
    readonly offset: number

    /**
     * 
     * @type {string}
     * @memberof BatchApiGetBreeds
     */
    readonly sort?: string

    /**
     * 
     * @type {'ASC' | 'ASC NULLS LAST' | 'ASC NULLS FIRST' | 'DESC' | 'DESC NULLS LAST' | 'DESC NULLS FIRST' | 'asc' | 'asc nulls last' | 'asc nulls first' | 'desc' | 'desc nulls last' | 'desc nulls first'}
     * @memberof BatchApiGetBreeds
     */
    readonly direction?: GetBreedsDirectionEnum
}

/**
 * Request parameters for sendBatch operation in BatchApi.
 * @export
 * @interface BatchApiSendBatchRequest
 */
export interface BatchApiSendBatchRequest {
    /**
     * 
     * @type {SendBatchBody}
     * @memberof BatchApiSendBatch
     */
    readonly sendBatchBody: SendBatchBody
}

/**
 * Request parameters for updateBatch operation in BatchApi.
 * @export
 * @interface BatchApiUpdateBatchRequest
 */
export interface BatchApiUpdateBatchRequest {
    /**
     * 
     * @type {string}
     * @memberof BatchApiUpdateBatch
     */
    readonly id: string

    /**
     * 
     * @type {UpdateBatchBody}
     * @memberof BatchApiUpdateBatch
     */
    readonly updateBatchBody: UpdateBatchBody
}

/**
 * BatchApi - object-oriented interface
 * @export
 * @class BatchApi
 * @extends {BaseAPI}
 */
export class BatchApi extends BaseAPI {
    /**
     * 
     * @param {BatchApiCloseBatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchApi
     */
    public closeBatch(requestParameters: BatchApiCloseBatchRequest, options?: RawAxiosRequestConfig) {
        return BatchApiFp(this.configuration).closeBatch(requestParameters.id, requestParameters.closeBatchBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BatchApiCreateBatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchApi
     */
    public createBatch(requestParameters: BatchApiCreateBatchRequest, options?: RawAxiosRequestConfig) {
        return BatchApiFp(this.configuration).createBatch(requestParameters.batchBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BatchApiCreateBatchCommentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchApi
     */
    public createBatchComment(requestParameters: BatchApiCreateBatchCommentRequest, options?: RawAxiosRequestConfig) {
        return BatchApiFp(this.configuration).createBatchComment(requestParameters.id, requestParameters.commentBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BatchApiDeleteBatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchApi
     */
    public deleteBatch(requestParameters: BatchApiDeleteBatchRequest, options?: RawAxiosRequestConfig) {
        return BatchApiFp(this.configuration).deleteBatch(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BatchApiGetBatchByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchApi
     */
    public getBatchById(requestParameters: BatchApiGetBatchByIdRequest, options?: RawAxiosRequestConfig) {
        return BatchApiFp(this.configuration).getBatchById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BatchApiGetBatchDeliveryDocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchApi
     */
    public getBatchDeliveryDocument(requestParameters: BatchApiGetBatchDeliveryDocumentRequest, options?: RawAxiosRequestConfig) {
        return BatchApiFp(this.configuration).getBatchDeliveryDocument(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BatchApiGetBatchEventsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchApi
     */
    public getBatchEvents(requestParameters: BatchApiGetBatchEventsRequest, options?: RawAxiosRequestConfig) {
        return BatchApiFp(this.configuration).getBatchEvents(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BatchApiGetBatchReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchApi
     */
    public getBatchReport(requestParameters: BatchApiGetBatchReportRequest, options?: RawAxiosRequestConfig) {
        return BatchApiFp(this.configuration).getBatchReport(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BatchApiGetBatchStatsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchApi
     */
    public getBatchStats(requestParameters: BatchApiGetBatchStatsRequest, options?: RawAxiosRequestConfig) {
        return BatchApiFp(this.configuration).getBatchStats(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BatchApiGetBatchesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchApi
     */
    public getBatches(requestParameters: BatchApiGetBatchesRequest, options?: RawAxiosRequestConfig) {
        return BatchApiFp(this.configuration).getBatches(requestParameters.limit, requestParameters.offset, requestParameters.customerIds, requestParameters.hatcheryIds, requestParameters.status, requestParameters.withData, requestParameters.start, requestParameters.end, requestParameters.search, requestParameters.sort, requestParameters.direction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BatchApiGetBatchesExportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchApi
     */
    public getBatchesExport(requestParameters: BatchApiGetBatchesExportRequest, options?: RawAxiosRequestConfig) {
        return BatchApiFp(this.configuration).getBatchesExport(requestParameters.limit, requestParameters.offset, requestParameters.customerIds, requestParameters.hatcheryIds, requestParameters.status, requestParameters.withData, requestParameters.start, requestParameters.end, requestParameters.search, requestParameters.sort, requestParameters.direction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BatchApiGetBreedsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchApi
     */
    public getBreeds(requestParameters: BatchApiGetBreedsRequest, options?: RawAxiosRequestConfig) {
        return BatchApiFp(this.configuration).getBreeds(requestParameters.limit, requestParameters.offset, requestParameters.sort, requestParameters.direction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchApi
     */
    public getLatestBatchEvents(options?: RawAxiosRequestConfig) {
        return BatchApiFp(this.configuration).getLatestBatchEvents(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchApi
     */
    public processBatches(options?: RawAxiosRequestConfig) {
        return BatchApiFp(this.configuration).processBatches(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BatchApiSendBatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchApi
     */
    public sendBatch(requestParameters: BatchApiSendBatchRequest, options?: RawAxiosRequestConfig) {
        return BatchApiFp(this.configuration).sendBatch(requestParameters.sendBatchBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BatchApiUpdateBatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchApi
     */
    public updateBatch(requestParameters: BatchApiUpdateBatchRequest, options?: RawAxiosRequestConfig) {
        return BatchApiFp(this.configuration).updateBatch(requestParameters.id, requestParameters.updateBatchBody, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetBatchesStatusEnum = {
    Inactive: 'inactive',
    Active: 'active',
    Closed: 'closed'
} as const;
export type GetBatchesStatusEnum = typeof GetBatchesStatusEnum[keyof typeof GetBatchesStatusEnum];
/**
 * @export
 */
export const GetBatchesDirectionEnum = {
    Asc: 'ASC',
    AscNullsLast: 'ASC NULLS LAST',
    AscNullsFirst: 'ASC NULLS FIRST',
    Desc: 'DESC',
    DescNullsLast: 'DESC NULLS LAST',
    DescNullsFirst: 'DESC NULLS FIRST',
    Asc2: 'asc',
    AscNullsLast2: 'asc nulls last',
    AscNullsFirst2: 'asc nulls first',
    Desc2: 'desc',
    DescNullsLast2: 'desc nulls last',
    DescNullsFirst2: 'desc nulls first'
} as const;
export type GetBatchesDirectionEnum = typeof GetBatchesDirectionEnum[keyof typeof GetBatchesDirectionEnum];
/**
 * @export
 */
export const GetBatchesExportStatusEnum = {
    Inactive: 'inactive',
    Active: 'active',
    Closed: 'closed'
} as const;
export type GetBatchesExportStatusEnum = typeof GetBatchesExportStatusEnum[keyof typeof GetBatchesExportStatusEnum];
/**
 * @export
 */
export const GetBatchesExportDirectionEnum = {
    Asc: 'ASC',
    AscNullsLast: 'ASC NULLS LAST',
    AscNullsFirst: 'ASC NULLS FIRST',
    Desc: 'DESC',
    DescNullsLast: 'DESC NULLS LAST',
    DescNullsFirst: 'DESC NULLS FIRST',
    Asc2: 'asc',
    AscNullsLast2: 'asc nulls last',
    AscNullsFirst2: 'asc nulls first',
    Desc2: 'desc',
    DescNullsLast2: 'desc nulls last',
    DescNullsFirst2: 'desc nulls first'
} as const;
export type GetBatchesExportDirectionEnum = typeof GetBatchesExportDirectionEnum[keyof typeof GetBatchesExportDirectionEnum];
/**
 * @export
 */
export const GetBreedsDirectionEnum = {
    Asc: 'ASC',
    AscNullsLast: 'ASC NULLS LAST',
    AscNullsFirst: 'ASC NULLS FIRST',
    Desc: 'DESC',
    DescNullsLast: 'DESC NULLS LAST',
    DescNullsFirst: 'DESC NULLS FIRST',
    Asc2: 'asc',
    AscNullsLast2: 'asc nulls last',
    AscNullsFirst2: 'asc nulls first',
    Desc2: 'desc',
    DescNullsLast2: 'desc nulls last',
    DescNullsFirst2: 'desc nulls first'
} as const;
export type GetBreedsDirectionEnum = typeof GetBreedsDirectionEnum[keyof typeof GetBreedsDirectionEnum];


/**
 * CustomerApi - axios parameter creator
 * @export
 */
export const CustomerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CustomerBody} customerBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomer: async (customerBody: CustomerBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerBody' is not null or undefined
            assertParamExists('createCustomer', 'customerBody', customerBody)
            const localVarPath = `/customers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {CustomerLocationBody} customerLocationBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomerLocation: async (id: string, customerLocationBody: CustomerLocationBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createCustomerLocation', 'id', id)
            // verify required parameter 'customerLocationBody' is not null or undefined
            assertParamExists('createCustomerLocation', 'customerLocationBody', customerLocationBody)
            const localVarPath = `/customers/{id}/location`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerLocationBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} customerLocationId 
         * @param {HouseBody} houseBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createHouse: async (id: string, customerLocationId: string, houseBody: HouseBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createHouse', 'id', id)
            // verify required parameter 'customerLocationId' is not null or undefined
            assertParamExists('createHouse', 'customerLocationId', customerLocationId)
            // verify required parameter 'houseBody' is not null or undefined
            assertParamExists('createHouse', 'houseBody', houseBody)
            const localVarPath = `/customers/{id}/location/{customerLocationId}/house`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"customerLocationId"}}`, encodeURIComponent(String(customerLocationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(houseBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomer: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCustomer', 'id', id)
            const localVarPath = `/customers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} customerLocationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomerLocation: async (id: string, customerLocationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCustomerLocation', 'id', id)
            // verify required parameter 'customerLocationId' is not null or undefined
            assertParamExists('deleteCustomerLocation', 'customerLocationId', customerLocationId)
            const localVarPath = `/customers/{id}/location/{customerLocationId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"customerLocationId"}}`, encodeURIComponent(String(customerLocationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} customerLocationId 
         * @param {string} houseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteHouse: async (id: string, customerLocationId: string, houseId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteHouse', 'id', id)
            // verify required parameter 'customerLocationId' is not null or undefined
            assertParamExists('deleteHouse', 'customerLocationId', customerLocationId)
            // verify required parameter 'houseId' is not null or undefined
            assertParamExists('deleteHouse', 'houseId', houseId)
            const localVarPath = `/customers/{id}/location/{customerLocationId}/house/{houseId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"customerLocationId"}}`, encodeURIComponent(String(customerLocationId)))
                .replace(`{${"houseId"}}`, encodeURIComponent(String(houseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCustomerById', 'id', id)
            const localVarPath = `/customers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerSubscriptions: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCustomerSubscriptions', 'id', id)
            const localVarPath = `/customers/{id}/subscriptions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {string} [country] 
         * @param {Array<string>} [hatcheryIds] 
         * @param {string} [search] 
         * @param {string} [sort] 
         * @param {GetCustomersDirectionEnum} [direction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomers: async (limit: number, offset: number, country?: string, hatcheryIds?: Array<string>, search?: string, sort?: string, direction?: GetCustomersDirectionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('getCustomers', 'limit', limit)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('getCustomers', 'offset', offset)
            const localVarPath = `/customers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (hatcheryIds) {
                localVarQueryParameter['hatcheryIds'] = hatcheryIds;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateCustomerBody} updateCustomerBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomer: async (id: string, updateCustomerBody: UpdateCustomerBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCustomer', 'id', id)
            // verify required parameter 'updateCustomerBody' is not null or undefined
            assertParamExists('updateCustomer', 'updateCustomerBody', updateCustomerBody)
            const localVarPath = `/customers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCustomerBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} customerLocationId 
         * @param {UpdateCustomerLocationBody} updateCustomerLocationBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerLocation: async (id: string, customerLocationId: string, updateCustomerLocationBody: UpdateCustomerLocationBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCustomerLocation', 'id', id)
            // verify required parameter 'customerLocationId' is not null or undefined
            assertParamExists('updateCustomerLocation', 'customerLocationId', customerLocationId)
            // verify required parameter 'updateCustomerLocationBody' is not null or undefined
            assertParamExists('updateCustomerLocation', 'updateCustomerLocationBody', updateCustomerLocationBody)
            const localVarPath = `/customers/{id}/location/{customerLocationId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"customerLocationId"}}`, encodeURIComponent(String(customerLocationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCustomerLocationBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} customerLocationId 
         * @param {string} houseId 
         * @param {UpdateHouseBody} updateHouseBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateHouse: async (id: string, customerLocationId: string, houseId: string, updateHouseBody: UpdateHouseBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateHouse', 'id', id)
            // verify required parameter 'customerLocationId' is not null or undefined
            assertParamExists('updateHouse', 'customerLocationId', customerLocationId)
            // verify required parameter 'houseId' is not null or undefined
            assertParamExists('updateHouse', 'houseId', houseId)
            // verify required parameter 'updateHouseBody' is not null or undefined
            assertParamExists('updateHouse', 'updateHouseBody', updateHouseBody)
            const localVarPath = `/customers/{id}/location/{customerLocationId}/house/{houseId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"customerLocationId"}}`, encodeURIComponent(String(customerLocationId)))
                .replace(`{${"houseId"}}`, encodeURIComponent(String(houseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateHouseBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerApi - functional programming interface
 * @export
 */
export const CustomerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CustomerBody} customerBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCustomer(customerBody: CustomerBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCustomer(customerBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.createCustomer']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {CustomerLocationBody} customerLocationBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCustomerLocation(id: string, customerLocationBody: CustomerLocationBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerLocationView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCustomerLocation(id, customerLocationBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.createCustomerLocation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} customerLocationId 
         * @param {HouseBody} houseBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createHouse(id: string, customerLocationId: string, houseBody: HouseBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HouseView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createHouse(id, customerLocationId, houseBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.createHouse']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCustomer(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCustomer(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.deleteCustomer']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} customerLocationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCustomerLocation(id: string, customerLocationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCustomerLocation(id, customerLocationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.deleteCustomerLocation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} customerLocationId 
         * @param {string} houseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteHouse(id: string, customerLocationId: string, houseId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteHouse(id, customerLocationId, houseId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.deleteHouse']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerDetailView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.getCustomerById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerSubscriptions(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListSubscriptionRoleView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerSubscriptions(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.getCustomerSubscriptions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {string} [country] 
         * @param {Array<string>} [hatcheryIds] 
         * @param {string} [search] 
         * @param {string} [sort] 
         * @param {GetCustomersDirectionEnum} [direction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomers(limit: number, offset: number, country?: string, hatcheryIds?: Array<string>, search?: string, sort?: string, direction?: GetCustomersDirectionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCustomerView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomers(limit, offset, country, hatcheryIds, search, sort, direction, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.getCustomers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateCustomerBody} updateCustomerBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCustomer(id: string, updateCustomerBody: UpdateCustomerBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCustomer(id, updateCustomerBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.updateCustomer']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} customerLocationId 
         * @param {UpdateCustomerLocationBody} updateCustomerLocationBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCustomerLocation(id: string, customerLocationId: string, updateCustomerLocationBody: UpdateCustomerLocationBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerLocationView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCustomerLocation(id, customerLocationId, updateCustomerLocationBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.updateCustomerLocation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} customerLocationId 
         * @param {string} houseId 
         * @param {UpdateHouseBody} updateHouseBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateHouse(id: string, customerLocationId: string, houseId: string, updateHouseBody: UpdateHouseBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HouseView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateHouse(id, customerLocationId, houseId, updateHouseBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.updateHouse']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CustomerApi - factory interface
 * @export
 */
export const CustomerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerApiFp(configuration)
    return {
        /**
         * 
         * @param {CustomerApiCreateCustomerRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomer(requestParameters: CustomerApiCreateCustomerRequest, options?: RawAxiosRequestConfig): AxiosPromise<CustomerView> {
            return localVarFp.createCustomer(requestParameters.customerBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomerApiCreateCustomerLocationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomerLocation(requestParameters: CustomerApiCreateCustomerLocationRequest, options?: RawAxiosRequestConfig): AxiosPromise<CustomerLocationView> {
            return localVarFp.createCustomerLocation(requestParameters.id, requestParameters.customerLocationBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomerApiCreateHouseRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createHouse(requestParameters: CustomerApiCreateHouseRequest, options?: RawAxiosRequestConfig): AxiosPromise<HouseView> {
            return localVarFp.createHouse(requestParameters.id, requestParameters.customerLocationId, requestParameters.houseBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomerApiDeleteCustomerRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomer(requestParameters: CustomerApiDeleteCustomerRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteCustomer(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomerApiDeleteCustomerLocationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomerLocation(requestParameters: CustomerApiDeleteCustomerLocationRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteCustomerLocation(requestParameters.id, requestParameters.customerLocationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomerApiDeleteHouseRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteHouse(requestParameters: CustomerApiDeleteHouseRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteHouse(requestParameters.id, requestParameters.customerLocationId, requestParameters.houseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomerApiGetCustomerByIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerById(requestParameters: CustomerApiGetCustomerByIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<CustomerDetailView> {
            return localVarFp.getCustomerById(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomerApiGetCustomerSubscriptionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerSubscriptions(requestParameters: CustomerApiGetCustomerSubscriptionsRequest, options?: RawAxiosRequestConfig): AxiosPromise<ListSubscriptionRoleView> {
            return localVarFp.getCustomerSubscriptions(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomerApiGetCustomersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomers(requestParameters: CustomerApiGetCustomersRequest, options?: RawAxiosRequestConfig): AxiosPromise<ListCustomerView> {
            return localVarFp.getCustomers(requestParameters.limit, requestParameters.offset, requestParameters.country, requestParameters.hatcheryIds, requestParameters.search, requestParameters.sort, requestParameters.direction, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomerApiUpdateCustomerRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomer(requestParameters: CustomerApiUpdateCustomerRequest, options?: RawAxiosRequestConfig): AxiosPromise<CustomerView> {
            return localVarFp.updateCustomer(requestParameters.id, requestParameters.updateCustomerBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomerApiUpdateCustomerLocationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerLocation(requestParameters: CustomerApiUpdateCustomerLocationRequest, options?: RawAxiosRequestConfig): AxiosPromise<CustomerLocationView> {
            return localVarFp.updateCustomerLocation(requestParameters.id, requestParameters.customerLocationId, requestParameters.updateCustomerLocationBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomerApiUpdateHouseRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateHouse(requestParameters: CustomerApiUpdateHouseRequest, options?: RawAxiosRequestConfig): AxiosPromise<HouseView> {
            return localVarFp.updateHouse(requestParameters.id, requestParameters.customerLocationId, requestParameters.houseId, requestParameters.updateHouseBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createCustomer operation in CustomerApi.
 * @export
 * @interface CustomerApiCreateCustomerRequest
 */
export interface CustomerApiCreateCustomerRequest {
    /**
     * 
     * @type {CustomerBody}
     * @memberof CustomerApiCreateCustomer
     */
    readonly customerBody: CustomerBody
}

/**
 * Request parameters for createCustomerLocation operation in CustomerApi.
 * @export
 * @interface CustomerApiCreateCustomerLocationRequest
 */
export interface CustomerApiCreateCustomerLocationRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomerApiCreateCustomerLocation
     */
    readonly id: string

    /**
     * 
     * @type {CustomerLocationBody}
     * @memberof CustomerApiCreateCustomerLocation
     */
    readonly customerLocationBody: CustomerLocationBody
}

/**
 * Request parameters for createHouse operation in CustomerApi.
 * @export
 * @interface CustomerApiCreateHouseRequest
 */
export interface CustomerApiCreateHouseRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomerApiCreateHouse
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof CustomerApiCreateHouse
     */
    readonly customerLocationId: string

    /**
     * 
     * @type {HouseBody}
     * @memberof CustomerApiCreateHouse
     */
    readonly houseBody: HouseBody
}

/**
 * Request parameters for deleteCustomer operation in CustomerApi.
 * @export
 * @interface CustomerApiDeleteCustomerRequest
 */
export interface CustomerApiDeleteCustomerRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomerApiDeleteCustomer
     */
    readonly id: string
}

/**
 * Request parameters for deleteCustomerLocation operation in CustomerApi.
 * @export
 * @interface CustomerApiDeleteCustomerLocationRequest
 */
export interface CustomerApiDeleteCustomerLocationRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomerApiDeleteCustomerLocation
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof CustomerApiDeleteCustomerLocation
     */
    readonly customerLocationId: string
}

/**
 * Request parameters for deleteHouse operation in CustomerApi.
 * @export
 * @interface CustomerApiDeleteHouseRequest
 */
export interface CustomerApiDeleteHouseRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomerApiDeleteHouse
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof CustomerApiDeleteHouse
     */
    readonly customerLocationId: string

    /**
     * 
     * @type {string}
     * @memberof CustomerApiDeleteHouse
     */
    readonly houseId: string
}

/**
 * Request parameters for getCustomerById operation in CustomerApi.
 * @export
 * @interface CustomerApiGetCustomerByIdRequest
 */
export interface CustomerApiGetCustomerByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomerApiGetCustomerById
     */
    readonly id: string
}

/**
 * Request parameters for getCustomerSubscriptions operation in CustomerApi.
 * @export
 * @interface CustomerApiGetCustomerSubscriptionsRequest
 */
export interface CustomerApiGetCustomerSubscriptionsRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomerApiGetCustomerSubscriptions
     */
    readonly id: string
}

/**
 * Request parameters for getCustomers operation in CustomerApi.
 * @export
 * @interface CustomerApiGetCustomersRequest
 */
export interface CustomerApiGetCustomersRequest {
    /**
     * 
     * @type {number}
     * @memberof CustomerApiGetCustomers
     */
    readonly limit: number

    /**
     * 
     * @type {number}
     * @memberof CustomerApiGetCustomers
     */
    readonly offset: number

    /**
     * 
     * @type {string}
     * @memberof CustomerApiGetCustomers
     */
    readonly country?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof CustomerApiGetCustomers
     */
    readonly hatcheryIds?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof CustomerApiGetCustomers
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof CustomerApiGetCustomers
     */
    readonly sort?: string

    /**
     * 
     * @type {'ASC' | 'ASC NULLS LAST' | 'ASC NULLS FIRST' | 'DESC' | 'DESC NULLS LAST' | 'DESC NULLS FIRST' | 'asc' | 'asc nulls last' | 'asc nulls first' | 'desc' | 'desc nulls last' | 'desc nulls first'}
     * @memberof CustomerApiGetCustomers
     */
    readonly direction?: GetCustomersDirectionEnum
}

/**
 * Request parameters for updateCustomer operation in CustomerApi.
 * @export
 * @interface CustomerApiUpdateCustomerRequest
 */
export interface CustomerApiUpdateCustomerRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomerApiUpdateCustomer
     */
    readonly id: string

    /**
     * 
     * @type {UpdateCustomerBody}
     * @memberof CustomerApiUpdateCustomer
     */
    readonly updateCustomerBody: UpdateCustomerBody
}

/**
 * Request parameters for updateCustomerLocation operation in CustomerApi.
 * @export
 * @interface CustomerApiUpdateCustomerLocationRequest
 */
export interface CustomerApiUpdateCustomerLocationRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomerApiUpdateCustomerLocation
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof CustomerApiUpdateCustomerLocation
     */
    readonly customerLocationId: string

    /**
     * 
     * @type {UpdateCustomerLocationBody}
     * @memberof CustomerApiUpdateCustomerLocation
     */
    readonly updateCustomerLocationBody: UpdateCustomerLocationBody
}

/**
 * Request parameters for updateHouse operation in CustomerApi.
 * @export
 * @interface CustomerApiUpdateHouseRequest
 */
export interface CustomerApiUpdateHouseRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomerApiUpdateHouse
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof CustomerApiUpdateHouse
     */
    readonly customerLocationId: string

    /**
     * 
     * @type {string}
     * @memberof CustomerApiUpdateHouse
     */
    readonly houseId: string

    /**
     * 
     * @type {UpdateHouseBody}
     * @memberof CustomerApiUpdateHouse
     */
    readonly updateHouseBody: UpdateHouseBody
}

/**
 * CustomerApi - object-oriented interface
 * @export
 * @class CustomerApi
 * @extends {BaseAPI}
 */
export class CustomerApi extends BaseAPI {
    /**
     * 
     * @param {CustomerApiCreateCustomerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public createCustomer(requestParameters: CustomerApiCreateCustomerRequest, options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).createCustomer(requestParameters.customerBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomerApiCreateCustomerLocationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public createCustomerLocation(requestParameters: CustomerApiCreateCustomerLocationRequest, options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).createCustomerLocation(requestParameters.id, requestParameters.customerLocationBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomerApiCreateHouseRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public createHouse(requestParameters: CustomerApiCreateHouseRequest, options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).createHouse(requestParameters.id, requestParameters.customerLocationId, requestParameters.houseBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomerApiDeleteCustomerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public deleteCustomer(requestParameters: CustomerApiDeleteCustomerRequest, options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).deleteCustomer(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomerApiDeleteCustomerLocationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public deleteCustomerLocation(requestParameters: CustomerApiDeleteCustomerLocationRequest, options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).deleteCustomerLocation(requestParameters.id, requestParameters.customerLocationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomerApiDeleteHouseRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public deleteHouse(requestParameters: CustomerApiDeleteHouseRequest, options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).deleteHouse(requestParameters.id, requestParameters.customerLocationId, requestParameters.houseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomerApiGetCustomerByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public getCustomerById(requestParameters: CustomerApiGetCustomerByIdRequest, options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).getCustomerById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomerApiGetCustomerSubscriptionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public getCustomerSubscriptions(requestParameters: CustomerApiGetCustomerSubscriptionsRequest, options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).getCustomerSubscriptions(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomerApiGetCustomersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public getCustomers(requestParameters: CustomerApiGetCustomersRequest, options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).getCustomers(requestParameters.limit, requestParameters.offset, requestParameters.country, requestParameters.hatcheryIds, requestParameters.search, requestParameters.sort, requestParameters.direction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomerApiUpdateCustomerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public updateCustomer(requestParameters: CustomerApiUpdateCustomerRequest, options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).updateCustomer(requestParameters.id, requestParameters.updateCustomerBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomerApiUpdateCustomerLocationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public updateCustomerLocation(requestParameters: CustomerApiUpdateCustomerLocationRequest, options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).updateCustomerLocation(requestParameters.id, requestParameters.customerLocationId, requestParameters.updateCustomerLocationBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomerApiUpdateHouseRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public updateHouse(requestParameters: CustomerApiUpdateHouseRequest, options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).updateHouse(requestParameters.id, requestParameters.customerLocationId, requestParameters.houseId, requestParameters.updateHouseBody, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetCustomersDirectionEnum = {
    Asc: 'ASC',
    AscNullsLast: 'ASC NULLS LAST',
    AscNullsFirst: 'ASC NULLS FIRST',
    Desc: 'DESC',
    DescNullsLast: 'DESC NULLS LAST',
    DescNullsFirst: 'DESC NULLS FIRST',
    Asc2: 'asc',
    AscNullsLast2: 'asc nulls last',
    AscNullsFirst2: 'asc nulls first',
    Desc2: 'desc',
    DescNullsLast2: 'desc nulls last',
    DescNullsFirst2: 'desc nulls first'
} as const;
export type GetCustomersDirectionEnum = typeof GetCustomersDirectionEnum[keyof typeof GetCustomersDirectionEnum];


/**
 * HatcheryApi - axios parameter creator
 * @export
 */
export const HatcheryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {HatcheryBody} hatcheryBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createHatchery: async (hatcheryBody: HatcheryBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hatcheryBody' is not null or undefined
            assertParamExists('createHatchery', 'hatcheryBody', hatcheryBody)
            const localVarPath = `/hatcheries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(hatcheryBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteHatchery: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteHatchery', 'id', id)
            const localVarPath = `/hatcheries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {string} [country] 
         * @param {string} [search] 
         * @param {string} [sort] 
         * @param {GetHatcheriesDirectionEnum} [direction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHatcheries: async (limit: number, offset: number, country?: string, search?: string, sort?: string, direction?: GetHatcheriesDirectionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('getHatcheries', 'limit', limit)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('getHatcheries', 'offset', offset)
            const localVarPath = `/hatcheries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHatcheryById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getHatcheryById', 'id', id)
            const localVarPath = `/hatcheries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHatcherySubscriptions: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getHatcherySubscriptions', 'id', id)
            const localVarPath = `/hatcheries/{id}/subscriptions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateHatcheryBody} updateHatcheryBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateHatchery: async (id: string, updateHatcheryBody: UpdateHatcheryBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateHatchery', 'id', id)
            // verify required parameter 'updateHatcheryBody' is not null or undefined
            assertParamExists('updateHatchery', 'updateHatcheryBody', updateHatcheryBody)
            const localVarPath = `/hatcheries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateHatcheryBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HatcheryApi - functional programming interface
 * @export
 */
export const HatcheryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HatcheryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {HatcheryBody} hatcheryBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createHatchery(hatcheryBody: HatcheryBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HatcheryView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createHatchery(hatcheryBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HatcheryApi.createHatchery']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteHatchery(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteHatchery(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HatcheryApi.deleteHatchery']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {string} [country] 
         * @param {string} [search] 
         * @param {string} [sort] 
         * @param {GetHatcheriesDirectionEnum} [direction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHatcheries(limit: number, offset: number, country?: string, search?: string, sort?: string, direction?: GetHatcheriesDirectionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListHatcheryView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHatcheries(limit, offset, country, search, sort, direction, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HatcheryApi.getHatcheries']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHatcheryById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HatcheryView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHatcheryById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HatcheryApi.getHatcheryById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHatcherySubscriptions(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListSubscriptionRoleView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHatcherySubscriptions(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HatcheryApi.getHatcherySubscriptions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateHatcheryBody} updateHatcheryBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateHatchery(id: string, updateHatcheryBody: UpdateHatcheryBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HatcheryView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateHatchery(id, updateHatcheryBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HatcheryApi.updateHatchery']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * HatcheryApi - factory interface
 * @export
 */
export const HatcheryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HatcheryApiFp(configuration)
    return {
        /**
         * 
         * @param {HatcheryApiCreateHatcheryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createHatchery(requestParameters: HatcheryApiCreateHatcheryRequest, options?: RawAxiosRequestConfig): AxiosPromise<HatcheryView> {
            return localVarFp.createHatchery(requestParameters.hatcheryBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {HatcheryApiDeleteHatcheryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteHatchery(requestParameters: HatcheryApiDeleteHatcheryRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteHatchery(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {HatcheryApiGetHatcheriesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHatcheries(requestParameters: HatcheryApiGetHatcheriesRequest, options?: RawAxiosRequestConfig): AxiosPromise<ListHatcheryView> {
            return localVarFp.getHatcheries(requestParameters.limit, requestParameters.offset, requestParameters.country, requestParameters.search, requestParameters.sort, requestParameters.direction, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {HatcheryApiGetHatcheryByIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHatcheryById(requestParameters: HatcheryApiGetHatcheryByIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<HatcheryView> {
            return localVarFp.getHatcheryById(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {HatcheryApiGetHatcherySubscriptionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHatcherySubscriptions(requestParameters: HatcheryApiGetHatcherySubscriptionsRequest, options?: RawAxiosRequestConfig): AxiosPromise<ListSubscriptionRoleView> {
            return localVarFp.getHatcherySubscriptions(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {HatcheryApiUpdateHatcheryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateHatchery(requestParameters: HatcheryApiUpdateHatcheryRequest, options?: RawAxiosRequestConfig): AxiosPromise<HatcheryView> {
            return localVarFp.updateHatchery(requestParameters.id, requestParameters.updateHatcheryBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createHatchery operation in HatcheryApi.
 * @export
 * @interface HatcheryApiCreateHatcheryRequest
 */
export interface HatcheryApiCreateHatcheryRequest {
    /**
     * 
     * @type {HatcheryBody}
     * @memberof HatcheryApiCreateHatchery
     */
    readonly hatcheryBody: HatcheryBody
}

/**
 * Request parameters for deleteHatchery operation in HatcheryApi.
 * @export
 * @interface HatcheryApiDeleteHatcheryRequest
 */
export interface HatcheryApiDeleteHatcheryRequest {
    /**
     * 
     * @type {string}
     * @memberof HatcheryApiDeleteHatchery
     */
    readonly id: string
}

/**
 * Request parameters for getHatcheries operation in HatcheryApi.
 * @export
 * @interface HatcheryApiGetHatcheriesRequest
 */
export interface HatcheryApiGetHatcheriesRequest {
    /**
     * 
     * @type {number}
     * @memberof HatcheryApiGetHatcheries
     */
    readonly limit: number

    /**
     * 
     * @type {number}
     * @memberof HatcheryApiGetHatcheries
     */
    readonly offset: number

    /**
     * 
     * @type {string}
     * @memberof HatcheryApiGetHatcheries
     */
    readonly country?: string

    /**
     * 
     * @type {string}
     * @memberof HatcheryApiGetHatcheries
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof HatcheryApiGetHatcheries
     */
    readonly sort?: string

    /**
     * 
     * @type {'ASC' | 'ASC NULLS LAST' | 'ASC NULLS FIRST' | 'DESC' | 'DESC NULLS LAST' | 'DESC NULLS FIRST' | 'asc' | 'asc nulls last' | 'asc nulls first' | 'desc' | 'desc nulls last' | 'desc nulls first'}
     * @memberof HatcheryApiGetHatcheries
     */
    readonly direction?: GetHatcheriesDirectionEnum
}

/**
 * Request parameters for getHatcheryById operation in HatcheryApi.
 * @export
 * @interface HatcheryApiGetHatcheryByIdRequest
 */
export interface HatcheryApiGetHatcheryByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof HatcheryApiGetHatcheryById
     */
    readonly id: string
}

/**
 * Request parameters for getHatcherySubscriptions operation in HatcheryApi.
 * @export
 * @interface HatcheryApiGetHatcherySubscriptionsRequest
 */
export interface HatcheryApiGetHatcherySubscriptionsRequest {
    /**
     * 
     * @type {string}
     * @memberof HatcheryApiGetHatcherySubscriptions
     */
    readonly id: string
}

/**
 * Request parameters for updateHatchery operation in HatcheryApi.
 * @export
 * @interface HatcheryApiUpdateHatcheryRequest
 */
export interface HatcheryApiUpdateHatcheryRequest {
    /**
     * 
     * @type {string}
     * @memberof HatcheryApiUpdateHatchery
     */
    readonly id: string

    /**
     * 
     * @type {UpdateHatcheryBody}
     * @memberof HatcheryApiUpdateHatchery
     */
    readonly updateHatcheryBody: UpdateHatcheryBody
}

/**
 * HatcheryApi - object-oriented interface
 * @export
 * @class HatcheryApi
 * @extends {BaseAPI}
 */
export class HatcheryApi extends BaseAPI {
    /**
     * 
     * @param {HatcheryApiCreateHatcheryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HatcheryApi
     */
    public createHatchery(requestParameters: HatcheryApiCreateHatcheryRequest, options?: RawAxiosRequestConfig) {
        return HatcheryApiFp(this.configuration).createHatchery(requestParameters.hatcheryBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {HatcheryApiDeleteHatcheryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HatcheryApi
     */
    public deleteHatchery(requestParameters: HatcheryApiDeleteHatcheryRequest, options?: RawAxiosRequestConfig) {
        return HatcheryApiFp(this.configuration).deleteHatchery(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {HatcheryApiGetHatcheriesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HatcheryApi
     */
    public getHatcheries(requestParameters: HatcheryApiGetHatcheriesRequest, options?: RawAxiosRequestConfig) {
        return HatcheryApiFp(this.configuration).getHatcheries(requestParameters.limit, requestParameters.offset, requestParameters.country, requestParameters.search, requestParameters.sort, requestParameters.direction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {HatcheryApiGetHatcheryByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HatcheryApi
     */
    public getHatcheryById(requestParameters: HatcheryApiGetHatcheryByIdRequest, options?: RawAxiosRequestConfig) {
        return HatcheryApiFp(this.configuration).getHatcheryById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {HatcheryApiGetHatcherySubscriptionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HatcheryApi
     */
    public getHatcherySubscriptions(requestParameters: HatcheryApiGetHatcherySubscriptionsRequest, options?: RawAxiosRequestConfig) {
        return HatcheryApiFp(this.configuration).getHatcherySubscriptions(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {HatcheryApiUpdateHatcheryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HatcheryApi
     */
    public updateHatchery(requestParameters: HatcheryApiUpdateHatcheryRequest, options?: RawAxiosRequestConfig) {
        return HatcheryApiFp(this.configuration).updateHatchery(requestParameters.id, requestParameters.updateHatcheryBody, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetHatcheriesDirectionEnum = {
    Asc: 'ASC',
    AscNullsLast: 'ASC NULLS LAST',
    AscNullsFirst: 'ASC NULLS FIRST',
    Desc: 'DESC',
    DescNullsLast: 'DESC NULLS LAST',
    DescNullsFirst: 'DESC NULLS FIRST',
    Asc2: 'asc',
    AscNullsLast2: 'asc nulls last',
    AscNullsFirst2: 'asc nulls first',
    Desc2: 'desc',
    DescNullsLast2: 'desc nulls last',
    DescNullsFirst2: 'desc nulls first'
} as const;
export type GetHatcheriesDirectionEnum = typeof GetHatcheriesDirectionEnum[keyof typeof GetHatcheriesDirectionEnum];


/**
 * InviteApi - axios parameter creator
 * @export
 */
export const InviteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ConfirmInviteBody} confirmInviteBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmInvite: async (confirmInviteBody: ConfirmInviteBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'confirmInviteBody' is not null or undefined
            assertParamExists('confirmInvite', 'confirmInviteBody', confirmInviteBody)
            const localVarPath = `/invites/confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(confirmInviteBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InviteBody} inviteBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvite: async (inviteBody: InviteBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inviteBody' is not null or undefined
            assertParamExists('createInvite', 'inviteBody', inviteBody)
            const localVarPath = `/invites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inviteBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InviteApi - functional programming interface
 * @export
 */
export const InviteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InviteApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ConfirmInviteBody} confirmInviteBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmInvite(confirmInviteBody: ConfirmInviteBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmInvite(confirmInviteBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InviteApi.confirmInvite']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InviteBody} inviteBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInvite(inviteBody: InviteBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInvite(inviteBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InviteApi.createInvite']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * InviteApi - factory interface
 * @export
 */
export const InviteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InviteApiFp(configuration)
    return {
        /**
         * 
         * @param {InviteApiConfirmInviteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmInvite(requestParameters: InviteApiConfirmInviteRequest, options?: RawAxiosRequestConfig): AxiosPromise<LoginView> {
            return localVarFp.confirmInvite(requestParameters.confirmInviteBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InviteApiCreateInviteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvite(requestParameters: InviteApiCreateInviteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.createInvite(requestParameters.inviteBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for confirmInvite operation in InviteApi.
 * @export
 * @interface InviteApiConfirmInviteRequest
 */
export interface InviteApiConfirmInviteRequest {
    /**
     * 
     * @type {ConfirmInviteBody}
     * @memberof InviteApiConfirmInvite
     */
    readonly confirmInviteBody: ConfirmInviteBody
}

/**
 * Request parameters for createInvite operation in InviteApi.
 * @export
 * @interface InviteApiCreateInviteRequest
 */
export interface InviteApiCreateInviteRequest {
    /**
     * 
     * @type {InviteBody}
     * @memberof InviteApiCreateInvite
     */
    readonly inviteBody: InviteBody
}

/**
 * InviteApi - object-oriented interface
 * @export
 * @class InviteApi
 * @extends {BaseAPI}
 */
export class InviteApi extends BaseAPI {
    /**
     * 
     * @param {InviteApiConfirmInviteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InviteApi
     */
    public confirmInvite(requestParameters: InviteApiConfirmInviteRequest, options?: RawAxiosRequestConfig) {
        return InviteApiFp(this.configuration).confirmInvite(requestParameters.confirmInviteBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InviteApiCreateInviteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InviteApi
     */
    public createInvite(requestParameters: InviteApiCreateInviteRequest, options?: RawAxiosRequestConfig) {
        return InviteApiFp(this.configuration).createInvite(requestParameters.inviteBody, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LegacyBoxApi - axios parameter creator
 * @export
 */
export const LegacyBoxApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ConfirmUpdateLegacyBoxBody} confirmUpdateLegacyBoxBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmUpdate: async (confirmUpdateLegacyBoxBody: ConfirmUpdateLegacyBoxBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'confirmUpdateLegacyBoxBody' is not null or undefined
            assertParamExists('confirmUpdate', 'confirmUpdateLegacyBoxBody', confirmUpdateLegacyBoxBody)
            const localVarPath = `/legacy-boxes/confirm-update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(confirmUpdateLegacyBoxBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateLegacyBoxBody} updateLegacyBoxBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUpdate: async (updateLegacyBoxBody: UpdateLegacyBoxBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateLegacyBoxBody' is not null or undefined
            assertParamExists('getUpdate', 'updateLegacyBoxBody', updateLegacyBoxBody)
            const localVarPath = `/legacy-boxes/get-update-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLegacyBoxBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        heartbeat: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/legacy-boxes/heartbeat`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        home: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/legacy-boxes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LogLegacyBoxBody} logLegacyBoxBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logger: async (logLegacyBoxBody: LogLegacyBoxBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'logLegacyBoxBody' is not null or undefined
            assertParamExists('logger', 'logLegacyBoxBody', logLegacyBoxBody)
            const localVarPath = `/legacy-boxes/logger`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(logLegacyBoxBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProcessFileLegacyBoxBody} processFileLegacyBoxBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processFile: async (processFileLegacyBoxBody: ProcessFileLegacyBoxBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processFileLegacyBoxBody' is not null or undefined
            assertParamExists('processFile', 'processFileLegacyBoxBody', processFileLegacyBoxBody)
            const localVarPath = `/legacy-boxes/process-file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(processFileLegacyBoxBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UploadFileLegacyBoxBody} uploadFileLegacyBoxBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile: async (uploadFileLegacyBoxBody: UploadFileLegacyBoxBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uploadFileLegacyBoxBody' is not null or undefined
            assertParamExists('uploadFile', 'uploadFileLegacyBoxBody', uploadFileLegacyBoxBody)
            const localVarPath = `/legacy-boxes/upload-file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadFileLegacyBoxBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LegacyBoxApi - functional programming interface
 * @export
 */
export const LegacyBoxApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LegacyBoxApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ConfirmUpdateLegacyBoxBody} confirmUpdateLegacyBoxBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmUpdate(confirmUpdateLegacyBoxBody: ConfirmUpdateLegacyBoxBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseLegacyBoxView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmUpdate(confirmUpdateLegacyBoxBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LegacyBoxApi.confirmUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateLegacyBoxBody} updateLegacyBoxBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUpdate(updateLegacyBoxBody: UpdateLegacyBoxBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateResponseLegacyBoxView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUpdate(updateLegacyBoxBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LegacyBoxApi.getUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async heartbeat(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.heartbeat(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LegacyBoxApi.heartbeat']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async home(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.home(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LegacyBoxApi.home']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {LogLegacyBoxBody} logLegacyBoxBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logger(logLegacyBoxBody: LogLegacyBoxBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseLegacyBoxView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logger(logLegacyBoxBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LegacyBoxApi.logger']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ProcessFileLegacyBoxBody} processFileLegacyBoxBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processFile(processFileLegacyBoxBody: ProcessFileLegacyBoxBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processFile(processFileLegacyBoxBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LegacyBoxApi.processFile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UploadFileLegacyBoxBody} uploadFileLegacyBoxBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadFile(uploadFileLegacyBoxBody: UploadFileLegacyBoxBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseLegacyBoxView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadFile(uploadFileLegacyBoxBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LegacyBoxApi.uploadFile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * LegacyBoxApi - factory interface
 * @export
 */
export const LegacyBoxApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LegacyBoxApiFp(configuration)
    return {
        /**
         * 
         * @param {LegacyBoxApiConfirmUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmUpdate(requestParameters: LegacyBoxApiConfirmUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<ResponseLegacyBoxView> {
            return localVarFp.confirmUpdate(requestParameters.confirmUpdateLegacyBoxBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LegacyBoxApiGetUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUpdate(requestParameters: LegacyBoxApiGetUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<UpdateResponseLegacyBoxView> {
            return localVarFp.getUpdate(requestParameters.updateLegacyBoxBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        heartbeat(options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.heartbeat(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        home(options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.home(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LegacyBoxApiLoggerRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logger(requestParameters: LegacyBoxApiLoggerRequest, options?: RawAxiosRequestConfig): AxiosPromise<ResponseLegacyBoxView> {
            return localVarFp.logger(requestParameters.logLegacyBoxBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LegacyBoxApiProcessFileRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processFile(requestParameters: LegacyBoxApiProcessFileRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.processFile(requestParameters.processFileLegacyBoxBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LegacyBoxApiUploadFileRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile(requestParameters: LegacyBoxApiUploadFileRequest, options?: RawAxiosRequestConfig): AxiosPromise<ResponseLegacyBoxView> {
            return localVarFp.uploadFile(requestParameters.uploadFileLegacyBoxBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for confirmUpdate operation in LegacyBoxApi.
 * @export
 * @interface LegacyBoxApiConfirmUpdateRequest
 */
export interface LegacyBoxApiConfirmUpdateRequest {
    /**
     * 
     * @type {ConfirmUpdateLegacyBoxBody}
     * @memberof LegacyBoxApiConfirmUpdate
     */
    readonly confirmUpdateLegacyBoxBody: ConfirmUpdateLegacyBoxBody
}

/**
 * Request parameters for getUpdate operation in LegacyBoxApi.
 * @export
 * @interface LegacyBoxApiGetUpdateRequest
 */
export interface LegacyBoxApiGetUpdateRequest {
    /**
     * 
     * @type {UpdateLegacyBoxBody}
     * @memberof LegacyBoxApiGetUpdate
     */
    readonly updateLegacyBoxBody: UpdateLegacyBoxBody
}

/**
 * Request parameters for logger operation in LegacyBoxApi.
 * @export
 * @interface LegacyBoxApiLoggerRequest
 */
export interface LegacyBoxApiLoggerRequest {
    /**
     * 
     * @type {LogLegacyBoxBody}
     * @memberof LegacyBoxApiLogger
     */
    readonly logLegacyBoxBody: LogLegacyBoxBody
}

/**
 * Request parameters for processFile operation in LegacyBoxApi.
 * @export
 * @interface LegacyBoxApiProcessFileRequest
 */
export interface LegacyBoxApiProcessFileRequest {
    /**
     * 
     * @type {ProcessFileLegacyBoxBody}
     * @memberof LegacyBoxApiProcessFile
     */
    readonly processFileLegacyBoxBody: ProcessFileLegacyBoxBody
}

/**
 * Request parameters for uploadFile operation in LegacyBoxApi.
 * @export
 * @interface LegacyBoxApiUploadFileRequest
 */
export interface LegacyBoxApiUploadFileRequest {
    /**
     * 
     * @type {UploadFileLegacyBoxBody}
     * @memberof LegacyBoxApiUploadFile
     */
    readonly uploadFileLegacyBoxBody: UploadFileLegacyBoxBody
}

/**
 * LegacyBoxApi - object-oriented interface
 * @export
 * @class LegacyBoxApi
 * @extends {BaseAPI}
 */
export class LegacyBoxApi extends BaseAPI {
    /**
     * 
     * @param {LegacyBoxApiConfirmUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegacyBoxApi
     */
    public confirmUpdate(requestParameters: LegacyBoxApiConfirmUpdateRequest, options?: RawAxiosRequestConfig) {
        return LegacyBoxApiFp(this.configuration).confirmUpdate(requestParameters.confirmUpdateLegacyBoxBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LegacyBoxApiGetUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegacyBoxApi
     */
    public getUpdate(requestParameters: LegacyBoxApiGetUpdateRequest, options?: RawAxiosRequestConfig) {
        return LegacyBoxApiFp(this.configuration).getUpdate(requestParameters.updateLegacyBoxBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegacyBoxApi
     */
    public heartbeat(options?: RawAxiosRequestConfig) {
        return LegacyBoxApiFp(this.configuration).heartbeat(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegacyBoxApi
     */
    public home(options?: RawAxiosRequestConfig) {
        return LegacyBoxApiFp(this.configuration).home(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LegacyBoxApiLoggerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegacyBoxApi
     */
    public logger(requestParameters: LegacyBoxApiLoggerRequest, options?: RawAxiosRequestConfig) {
        return LegacyBoxApiFp(this.configuration).logger(requestParameters.logLegacyBoxBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LegacyBoxApiProcessFileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegacyBoxApi
     */
    public processFile(requestParameters: LegacyBoxApiProcessFileRequest, options?: RawAxiosRequestConfig) {
        return LegacyBoxApiFp(this.configuration).processFile(requestParameters.processFileLegacyBoxBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LegacyBoxApiUploadFileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegacyBoxApi
     */
    public uploadFile(requestParameters: LegacyBoxApiUploadFileRequest, options?: RawAxiosRequestConfig) {
        return LegacyBoxApiFp(this.configuration).uploadFile(requestParameters.uploadFileLegacyBoxBody, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MeasurementApi - axios parameter creator
 * @export
 */
export const MeasurementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processMeasurements: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/measurements/process`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MeasurementApi - functional programming interface
 * @export
 */
export const MeasurementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MeasurementApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processMeasurements(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeasurementLogView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processMeasurements(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MeasurementApi.processMeasurements']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MeasurementApi - factory interface
 * @export
 */
export const MeasurementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MeasurementApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processMeasurements(options?: RawAxiosRequestConfig): AxiosPromise<MeasurementLogView> {
            return localVarFp.processMeasurements(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MeasurementApi - object-oriented interface
 * @export
 * @class MeasurementApi
 * @extends {BaseAPI}
 */
export class MeasurementApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementApi
     */
    public processMeasurements(options?: RawAxiosRequestConfig) {
        return MeasurementApiFp(this.configuration).processMeasurements(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RoleApi - axios parameter creator
 * @export
 */
export const RoleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRole: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteRole', 'id', id)
            const localVarPath = `/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {Array<string>} [hatcheryIds] 
         * @param {Array<string>} [customerIds] 
         * @param {string} [search] 
         * @param {string} [sort] 
         * @param {GetRolesDirectionEnum} [direction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoles: async (limit: number, offset: number, hatcheryIds?: Array<string>, customerIds?: Array<string>, search?: string, sort?: string, direction?: GetRolesDirectionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('getRoles', 'limit', limit)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('getRoles', 'offset', offset)
            const localVarPath = `/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (hatcheryIds) {
                localVarQueryParameter['hatcheryIds'] = hatcheryIds;
            }

            if (customerIds) {
                localVarQueryParameter['customerIds'] = customerIds;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateRoleBody} updateRoleBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRole: async (id: string, updateRoleBody: UpdateRoleBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateRole', 'id', id)
            // verify required parameter 'updateRoleBody' is not null or undefined
            assertParamExists('updateRole', 'updateRoleBody', updateRoleBody)
            const localVarPath = `/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRoleBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoleApi - functional programming interface
 * @export
 */
export const RoleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRole(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRole(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RoleApi.deleteRole']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {Array<string>} [hatcheryIds] 
         * @param {Array<string>} [customerIds] 
         * @param {string} [search] 
         * @param {string} [sort] 
         * @param {GetRolesDirectionEnum} [direction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoles(limit: number, offset: number, hatcheryIds?: Array<string>, customerIds?: Array<string>, search?: string, sort?: string, direction?: GetRolesDirectionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListRoleView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoles(limit, offset, hatcheryIds, customerIds, search, sort, direction, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RoleApi.getRoles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateRoleBody} updateRoleBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRole(id: string, updateRoleBody: UpdateRoleBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRole(id, updateRoleBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RoleApi.updateRole']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RoleApi - factory interface
 * @export
 */
export const RoleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoleApiFp(configuration)
    return {
        /**
         * 
         * @param {RoleApiDeleteRoleRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRole(requestParameters: RoleApiDeleteRoleRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteRole(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RoleApiGetRolesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoles(requestParameters: RoleApiGetRolesRequest, options?: RawAxiosRequestConfig): AxiosPromise<ListRoleView> {
            return localVarFp.getRoles(requestParameters.limit, requestParameters.offset, requestParameters.hatcheryIds, requestParameters.customerIds, requestParameters.search, requestParameters.sort, requestParameters.direction, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RoleApiUpdateRoleRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRole(requestParameters: RoleApiUpdateRoleRequest, options?: RawAxiosRequestConfig): AxiosPromise<RoleView> {
            return localVarFp.updateRole(requestParameters.id, requestParameters.updateRoleBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for deleteRole operation in RoleApi.
 * @export
 * @interface RoleApiDeleteRoleRequest
 */
export interface RoleApiDeleteRoleRequest {
    /**
     * 
     * @type {string}
     * @memberof RoleApiDeleteRole
     */
    readonly id: string
}

/**
 * Request parameters for getRoles operation in RoleApi.
 * @export
 * @interface RoleApiGetRolesRequest
 */
export interface RoleApiGetRolesRequest {
    /**
     * 
     * @type {number}
     * @memberof RoleApiGetRoles
     */
    readonly limit: number

    /**
     * 
     * @type {number}
     * @memberof RoleApiGetRoles
     */
    readonly offset: number

    /**
     * 
     * @type {Array<string>}
     * @memberof RoleApiGetRoles
     */
    readonly hatcheryIds?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof RoleApiGetRoles
     */
    readonly customerIds?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof RoleApiGetRoles
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof RoleApiGetRoles
     */
    readonly sort?: string

    /**
     * 
     * @type {'ASC' | 'ASC NULLS LAST' | 'ASC NULLS FIRST' | 'DESC' | 'DESC NULLS LAST' | 'DESC NULLS FIRST' | 'asc' | 'asc nulls last' | 'asc nulls first' | 'desc' | 'desc nulls last' | 'desc nulls first'}
     * @memberof RoleApiGetRoles
     */
    readonly direction?: GetRolesDirectionEnum
}

/**
 * Request parameters for updateRole operation in RoleApi.
 * @export
 * @interface RoleApiUpdateRoleRequest
 */
export interface RoleApiUpdateRoleRequest {
    /**
     * 
     * @type {string}
     * @memberof RoleApiUpdateRole
     */
    readonly id: string

    /**
     * 
     * @type {UpdateRoleBody}
     * @memberof RoleApiUpdateRole
     */
    readonly updateRoleBody: UpdateRoleBody
}

/**
 * RoleApi - object-oriented interface
 * @export
 * @class RoleApi
 * @extends {BaseAPI}
 */
export class RoleApi extends BaseAPI {
    /**
     * 
     * @param {RoleApiDeleteRoleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public deleteRole(requestParameters: RoleApiDeleteRoleRequest, options?: RawAxiosRequestConfig) {
        return RoleApiFp(this.configuration).deleteRole(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RoleApiGetRolesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public getRoles(requestParameters: RoleApiGetRolesRequest, options?: RawAxiosRequestConfig) {
        return RoleApiFp(this.configuration).getRoles(requestParameters.limit, requestParameters.offset, requestParameters.hatcheryIds, requestParameters.customerIds, requestParameters.search, requestParameters.sort, requestParameters.direction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RoleApiUpdateRoleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public updateRole(requestParameters: RoleApiUpdateRoleRequest, options?: RawAxiosRequestConfig) {
        return RoleApiFp(this.configuration).updateRole(requestParameters.id, requestParameters.updateRoleBody, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetRolesDirectionEnum = {
    Asc: 'ASC',
    AscNullsLast: 'ASC NULLS LAST',
    AscNullsFirst: 'ASC NULLS FIRST',
    Desc: 'DESC',
    DescNullsLast: 'DESC NULLS LAST',
    DescNullsFirst: 'DESC NULLS FIRST',
    Asc2: 'asc',
    AscNullsLast2: 'asc nulls last',
    AscNullsFirst2: 'asc nulls first',
    Desc2: 'desc',
    DescNullsLast2: 'desc nulls last',
    DescNullsFirst2: 'desc nulls first'
} as const;
export type GetRolesDirectionEnum = typeof GetRolesDirectionEnum[keyof typeof GetRolesDirectionEnum];


/**
 * SensorApi - axios parameter creator
 * @export
 */
export const SensorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {SensorSetBody} sensorSetBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSensorSet: async (sensorSetBody: SensorSetBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sensorSetBody' is not null or undefined
            assertParamExists('createSensorSet', 'sensorSetBody', sensorSetBody)
            const localVarPath = `/sensors/sets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sensorSetBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {string} [search] 
         * @param {string} [sort] 
         * @param {GetGatewaysDirectionEnum} [direction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGateways: async (limit: number, offset: number, search?: string, sort?: string, direction?: GetGatewaysDirectionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('getGateways', 'limit', limit)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('getGateways', 'offset', offset)
            const localVarPath = `/sensors/gateways`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {Array<string>} [hatcheryIds] 
         * @param {string} [search] 
         * @param {string} [sort] 
         * @param {GetSensorSetsDirectionEnum} [direction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSensorSets: async (limit: number, offset: number, hatcheryIds?: Array<string>, search?: string, sort?: string, direction?: GetSensorSetsDirectionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('getSensorSets', 'limit', limit)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('getSensorSets', 'offset', offset)
            const localVarPath = `/sensors/sets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (hatcheryIds) {
                localVarQueryParameter['hatcheryIds'] = hatcheryIds;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {GetSensorsTypeEnum} [type] 
         * @param {string} [search] 
         * @param {string} [sort] 
         * @param {GetSensorsDirectionEnum} [direction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSensors: async (limit: number, offset: number, type?: GetSensorsTypeEnum, search?: string, sort?: string, direction?: GetSensorsDirectionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('getSensors', 'limit', limit)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('getSensors', 'offset', offset)
            const localVarPath = `/sensors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateGatewayBody} updateGatewayBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGateway: async (id: string, updateGatewayBody: UpdateGatewayBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateGateway', 'id', id)
            // verify required parameter 'updateGatewayBody' is not null or undefined
            assertParamExists('updateGateway', 'updateGatewayBody', updateGatewayBody)
            const localVarPath = `/sensors/gateways/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateGatewayBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateSensorBody} updateSensorBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSensor: async (id: string, updateSensorBody: UpdateSensorBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSensor', 'id', id)
            // verify required parameter 'updateSensorBody' is not null or undefined
            assertParamExists('updateSensor', 'updateSensorBody', updateSensorBody)
            const localVarPath = `/sensors/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSensorBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateSensorSetBody} updateSensorSetBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSensorSet: async (id: string, updateSensorSetBody: UpdateSensorSetBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSensorSet', 'id', id)
            // verify required parameter 'updateSensorSetBody' is not null or undefined
            assertParamExists('updateSensorSet', 'updateSensorSetBody', updateSensorSetBody)
            const localVarPath = `/sensors/sets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSensorSetBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SensorApi - functional programming interface
 * @export
 */
export const SensorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SensorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {SensorSetBody} sensorSetBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSensorSet(sensorSetBody: SensorSetBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SensorSetView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSensorSet(sensorSetBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SensorApi.createSensorSet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {string} [search] 
         * @param {string} [sort] 
         * @param {GetGatewaysDirectionEnum} [direction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGateways(limit: number, offset: number, search?: string, sort?: string, direction?: GetGatewaysDirectionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListGatewayView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGateways(limit, offset, search, sort, direction, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SensorApi.getGateways']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {Array<string>} [hatcheryIds] 
         * @param {string} [search] 
         * @param {string} [sort] 
         * @param {GetSensorSetsDirectionEnum} [direction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSensorSets(limit: number, offset: number, hatcheryIds?: Array<string>, search?: string, sort?: string, direction?: GetSensorSetsDirectionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListSensorSetView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSensorSets(limit, offset, hatcheryIds, search, sort, direction, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SensorApi.getSensorSets']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {GetSensorsTypeEnum} [type] 
         * @param {string} [search] 
         * @param {string} [sort] 
         * @param {GetSensorsDirectionEnum} [direction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSensors(limit: number, offset: number, type?: GetSensorsTypeEnum, search?: string, sort?: string, direction?: GetSensorsDirectionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListSensorView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSensors(limit, offset, type, search, sort, direction, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SensorApi.getSensors']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateGatewayBody} updateGatewayBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGateway(id: string, updateGatewayBody: UpdateGatewayBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GatewayView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateGateway(id, updateGatewayBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SensorApi.updateGateway']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateSensorBody} updateSensorBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSensor(id: string, updateSensorBody: UpdateSensorBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SensorView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSensor(id, updateSensorBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SensorApi.updateSensor']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateSensorSetBody} updateSensorSetBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSensorSet(id: string, updateSensorSetBody: UpdateSensorSetBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SensorSetView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSensorSet(id, updateSensorSetBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SensorApi.updateSensorSet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SensorApi - factory interface
 * @export
 */
export const SensorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SensorApiFp(configuration)
    return {
        /**
         * 
         * @param {SensorApiCreateSensorSetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSensorSet(requestParameters: SensorApiCreateSensorSetRequest, options?: RawAxiosRequestConfig): AxiosPromise<SensorSetView> {
            return localVarFp.createSensorSet(requestParameters.sensorSetBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SensorApiGetGatewaysRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGateways(requestParameters: SensorApiGetGatewaysRequest, options?: RawAxiosRequestConfig): AxiosPromise<ListGatewayView> {
            return localVarFp.getGateways(requestParameters.limit, requestParameters.offset, requestParameters.search, requestParameters.sort, requestParameters.direction, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SensorApiGetSensorSetsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSensorSets(requestParameters: SensorApiGetSensorSetsRequest, options?: RawAxiosRequestConfig): AxiosPromise<ListSensorSetView> {
            return localVarFp.getSensorSets(requestParameters.limit, requestParameters.offset, requestParameters.hatcheryIds, requestParameters.search, requestParameters.sort, requestParameters.direction, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SensorApiGetSensorsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSensors(requestParameters: SensorApiGetSensorsRequest, options?: RawAxiosRequestConfig): AxiosPromise<ListSensorView> {
            return localVarFp.getSensors(requestParameters.limit, requestParameters.offset, requestParameters.type, requestParameters.search, requestParameters.sort, requestParameters.direction, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SensorApiUpdateGatewayRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGateway(requestParameters: SensorApiUpdateGatewayRequest, options?: RawAxiosRequestConfig): AxiosPromise<GatewayView> {
            return localVarFp.updateGateway(requestParameters.id, requestParameters.updateGatewayBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SensorApiUpdateSensorRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSensor(requestParameters: SensorApiUpdateSensorRequest, options?: RawAxiosRequestConfig): AxiosPromise<SensorView> {
            return localVarFp.updateSensor(requestParameters.id, requestParameters.updateSensorBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SensorApiUpdateSensorSetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSensorSet(requestParameters: SensorApiUpdateSensorSetRequest, options?: RawAxiosRequestConfig): AxiosPromise<SensorSetView> {
            return localVarFp.updateSensorSet(requestParameters.id, requestParameters.updateSensorSetBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createSensorSet operation in SensorApi.
 * @export
 * @interface SensorApiCreateSensorSetRequest
 */
export interface SensorApiCreateSensorSetRequest {
    /**
     * 
     * @type {SensorSetBody}
     * @memberof SensorApiCreateSensorSet
     */
    readonly sensorSetBody: SensorSetBody
}

/**
 * Request parameters for getGateways operation in SensorApi.
 * @export
 * @interface SensorApiGetGatewaysRequest
 */
export interface SensorApiGetGatewaysRequest {
    /**
     * 
     * @type {number}
     * @memberof SensorApiGetGateways
     */
    readonly limit: number

    /**
     * 
     * @type {number}
     * @memberof SensorApiGetGateways
     */
    readonly offset: number

    /**
     * 
     * @type {string}
     * @memberof SensorApiGetGateways
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof SensorApiGetGateways
     */
    readonly sort?: string

    /**
     * 
     * @type {'ASC' | 'ASC NULLS LAST' | 'ASC NULLS FIRST' | 'DESC' | 'DESC NULLS LAST' | 'DESC NULLS FIRST' | 'asc' | 'asc nulls last' | 'asc nulls first' | 'desc' | 'desc nulls last' | 'desc nulls first'}
     * @memberof SensorApiGetGateways
     */
    readonly direction?: GetGatewaysDirectionEnum
}

/**
 * Request parameters for getSensorSets operation in SensorApi.
 * @export
 * @interface SensorApiGetSensorSetsRequest
 */
export interface SensorApiGetSensorSetsRequest {
    /**
     * 
     * @type {number}
     * @memberof SensorApiGetSensorSets
     */
    readonly limit: number

    /**
     * 
     * @type {number}
     * @memberof SensorApiGetSensorSets
     */
    readonly offset: number

    /**
     * 
     * @type {Array<string>}
     * @memberof SensorApiGetSensorSets
     */
    readonly hatcheryIds?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof SensorApiGetSensorSets
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof SensorApiGetSensorSets
     */
    readonly sort?: string

    /**
     * 
     * @type {'ASC' | 'ASC NULLS LAST' | 'ASC NULLS FIRST' | 'DESC' | 'DESC NULLS LAST' | 'DESC NULLS FIRST' | 'asc' | 'asc nulls last' | 'asc nulls first' | 'desc' | 'desc nulls last' | 'desc nulls first'}
     * @memberof SensorApiGetSensorSets
     */
    readonly direction?: GetSensorSetsDirectionEnum
}

/**
 * Request parameters for getSensors operation in SensorApi.
 * @export
 * @interface SensorApiGetSensorsRequest
 */
export interface SensorApiGetSensorsRequest {
    /**
     * 
     * @type {number}
     * @memberof SensorApiGetSensors
     */
    readonly limit: number

    /**
     * 
     * @type {number}
     * @memberof SensorApiGetSensors
     */
    readonly offset: number

    /**
     * 
     * @type {'ovoscan' | 'external'}
     * @memberof SensorApiGetSensors
     */
    readonly type?: GetSensorsTypeEnum

    /**
     * 
     * @type {string}
     * @memberof SensorApiGetSensors
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof SensorApiGetSensors
     */
    readonly sort?: string

    /**
     * 
     * @type {'ASC' | 'ASC NULLS LAST' | 'ASC NULLS FIRST' | 'DESC' | 'DESC NULLS LAST' | 'DESC NULLS FIRST' | 'asc' | 'asc nulls last' | 'asc nulls first' | 'desc' | 'desc nulls last' | 'desc nulls first'}
     * @memberof SensorApiGetSensors
     */
    readonly direction?: GetSensorsDirectionEnum
}

/**
 * Request parameters for updateGateway operation in SensorApi.
 * @export
 * @interface SensorApiUpdateGatewayRequest
 */
export interface SensorApiUpdateGatewayRequest {
    /**
     * 
     * @type {string}
     * @memberof SensorApiUpdateGateway
     */
    readonly id: string

    /**
     * 
     * @type {UpdateGatewayBody}
     * @memberof SensorApiUpdateGateway
     */
    readonly updateGatewayBody: UpdateGatewayBody
}

/**
 * Request parameters for updateSensor operation in SensorApi.
 * @export
 * @interface SensorApiUpdateSensorRequest
 */
export interface SensorApiUpdateSensorRequest {
    /**
     * 
     * @type {string}
     * @memberof SensorApiUpdateSensor
     */
    readonly id: string

    /**
     * 
     * @type {UpdateSensorBody}
     * @memberof SensorApiUpdateSensor
     */
    readonly updateSensorBody: UpdateSensorBody
}

/**
 * Request parameters for updateSensorSet operation in SensorApi.
 * @export
 * @interface SensorApiUpdateSensorSetRequest
 */
export interface SensorApiUpdateSensorSetRequest {
    /**
     * 
     * @type {string}
     * @memberof SensorApiUpdateSensorSet
     */
    readonly id: string

    /**
     * 
     * @type {UpdateSensorSetBody}
     * @memberof SensorApiUpdateSensorSet
     */
    readonly updateSensorSetBody: UpdateSensorSetBody
}

/**
 * SensorApi - object-oriented interface
 * @export
 * @class SensorApi
 * @extends {BaseAPI}
 */
export class SensorApi extends BaseAPI {
    /**
     * 
     * @param {SensorApiCreateSensorSetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SensorApi
     */
    public createSensorSet(requestParameters: SensorApiCreateSensorSetRequest, options?: RawAxiosRequestConfig) {
        return SensorApiFp(this.configuration).createSensorSet(requestParameters.sensorSetBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SensorApiGetGatewaysRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SensorApi
     */
    public getGateways(requestParameters: SensorApiGetGatewaysRequest, options?: RawAxiosRequestConfig) {
        return SensorApiFp(this.configuration).getGateways(requestParameters.limit, requestParameters.offset, requestParameters.search, requestParameters.sort, requestParameters.direction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SensorApiGetSensorSetsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SensorApi
     */
    public getSensorSets(requestParameters: SensorApiGetSensorSetsRequest, options?: RawAxiosRequestConfig) {
        return SensorApiFp(this.configuration).getSensorSets(requestParameters.limit, requestParameters.offset, requestParameters.hatcheryIds, requestParameters.search, requestParameters.sort, requestParameters.direction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SensorApiGetSensorsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SensorApi
     */
    public getSensors(requestParameters: SensorApiGetSensorsRequest, options?: RawAxiosRequestConfig) {
        return SensorApiFp(this.configuration).getSensors(requestParameters.limit, requestParameters.offset, requestParameters.type, requestParameters.search, requestParameters.sort, requestParameters.direction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SensorApiUpdateGatewayRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SensorApi
     */
    public updateGateway(requestParameters: SensorApiUpdateGatewayRequest, options?: RawAxiosRequestConfig) {
        return SensorApiFp(this.configuration).updateGateway(requestParameters.id, requestParameters.updateGatewayBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SensorApiUpdateSensorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SensorApi
     */
    public updateSensor(requestParameters: SensorApiUpdateSensorRequest, options?: RawAxiosRequestConfig) {
        return SensorApiFp(this.configuration).updateSensor(requestParameters.id, requestParameters.updateSensorBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SensorApiUpdateSensorSetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SensorApi
     */
    public updateSensorSet(requestParameters: SensorApiUpdateSensorSetRequest, options?: RawAxiosRequestConfig) {
        return SensorApiFp(this.configuration).updateSensorSet(requestParameters.id, requestParameters.updateSensorSetBody, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetGatewaysDirectionEnum = {
    Asc: 'ASC',
    AscNullsLast: 'ASC NULLS LAST',
    AscNullsFirst: 'ASC NULLS FIRST',
    Desc: 'DESC',
    DescNullsLast: 'DESC NULLS LAST',
    DescNullsFirst: 'DESC NULLS FIRST',
    Asc2: 'asc',
    AscNullsLast2: 'asc nulls last',
    AscNullsFirst2: 'asc nulls first',
    Desc2: 'desc',
    DescNullsLast2: 'desc nulls last',
    DescNullsFirst2: 'desc nulls first'
} as const;
export type GetGatewaysDirectionEnum = typeof GetGatewaysDirectionEnum[keyof typeof GetGatewaysDirectionEnum];
/**
 * @export
 */
export const GetSensorSetsDirectionEnum = {
    Asc: 'ASC',
    AscNullsLast: 'ASC NULLS LAST',
    AscNullsFirst: 'ASC NULLS FIRST',
    Desc: 'DESC',
    DescNullsLast: 'DESC NULLS LAST',
    DescNullsFirst: 'DESC NULLS FIRST',
    Asc2: 'asc',
    AscNullsLast2: 'asc nulls last',
    AscNullsFirst2: 'asc nulls first',
    Desc2: 'desc',
    DescNullsLast2: 'desc nulls last',
    DescNullsFirst2: 'desc nulls first'
} as const;
export type GetSensorSetsDirectionEnum = typeof GetSensorSetsDirectionEnum[keyof typeof GetSensorSetsDirectionEnum];
/**
 * @export
 */
export const GetSensorsTypeEnum = {
    Ovoscan: 'ovoscan',
    External: 'external'
} as const;
export type GetSensorsTypeEnum = typeof GetSensorsTypeEnum[keyof typeof GetSensorsTypeEnum];
/**
 * @export
 */
export const GetSensorsDirectionEnum = {
    Asc: 'ASC',
    AscNullsLast: 'ASC NULLS LAST',
    AscNullsFirst: 'ASC NULLS FIRST',
    Desc: 'DESC',
    DescNullsLast: 'DESC NULLS LAST',
    DescNullsFirst: 'DESC NULLS FIRST',
    Asc2: 'asc',
    AscNullsLast2: 'asc nulls last',
    AscNullsFirst2: 'asc nulls first',
    Desc2: 'desc',
    DescNullsLast2: 'desc nulls last',
    DescNullsFirst2: 'desc nulls first'
} as const;
export type GetSensorsDirectionEnum = typeof GetSensorsDirectionEnum[keyof typeof GetSensorsDirectionEnum];


/**
 * SubscriptionApi - axios parameter creator
 * @export
 */
export const SubscriptionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {SubscriptionBody} subscriptionBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubscription: async (subscriptionBody: SubscriptionBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriptionBody' is not null or undefined
            assertParamExists('createSubscription', 'subscriptionBody', subscriptionBody)
            const localVarPath = `/subscriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(subscriptionBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateSubscriptionBody} updateSubscriptionBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubscription: async (id: string, updateSubscriptionBody: UpdateSubscriptionBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSubscription', 'id', id)
            // verify required parameter 'updateSubscriptionBody' is not null or undefined
            assertParamExists('updateSubscription', 'updateSubscriptionBody', updateSubscriptionBody)
            const localVarPath = `/subscriptions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSubscriptionBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubscriptionApi - functional programming interface
 * @export
 */
export const SubscriptionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SubscriptionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {SubscriptionBody} subscriptionBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSubscription(subscriptionBody: SubscriptionBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriptionView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSubscription(subscriptionBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SubscriptionApi.createSubscription']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateSubscriptionBody} updateSubscriptionBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSubscription(id: string, updateSubscriptionBody: UpdateSubscriptionBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriptionView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSubscription(id, updateSubscriptionBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SubscriptionApi.updateSubscription']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SubscriptionApi - factory interface
 * @export
 */
export const SubscriptionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SubscriptionApiFp(configuration)
    return {
        /**
         * 
         * @param {SubscriptionApiCreateSubscriptionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubscription(requestParameters: SubscriptionApiCreateSubscriptionRequest, options?: RawAxiosRequestConfig): AxiosPromise<SubscriptionView> {
            return localVarFp.createSubscription(requestParameters.subscriptionBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SubscriptionApiUpdateSubscriptionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubscription(requestParameters: SubscriptionApiUpdateSubscriptionRequest, options?: RawAxiosRequestConfig): AxiosPromise<SubscriptionView> {
            return localVarFp.updateSubscription(requestParameters.id, requestParameters.updateSubscriptionBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createSubscription operation in SubscriptionApi.
 * @export
 * @interface SubscriptionApiCreateSubscriptionRequest
 */
export interface SubscriptionApiCreateSubscriptionRequest {
    /**
     * 
     * @type {SubscriptionBody}
     * @memberof SubscriptionApiCreateSubscription
     */
    readonly subscriptionBody: SubscriptionBody
}

/**
 * Request parameters for updateSubscription operation in SubscriptionApi.
 * @export
 * @interface SubscriptionApiUpdateSubscriptionRequest
 */
export interface SubscriptionApiUpdateSubscriptionRequest {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionApiUpdateSubscription
     */
    readonly id: string

    /**
     * 
     * @type {UpdateSubscriptionBody}
     * @memberof SubscriptionApiUpdateSubscription
     */
    readonly updateSubscriptionBody: UpdateSubscriptionBody
}

/**
 * SubscriptionApi - object-oriented interface
 * @export
 * @class SubscriptionApi
 * @extends {BaseAPI}
 */
export class SubscriptionApi extends BaseAPI {
    /**
     * 
     * @param {SubscriptionApiCreateSubscriptionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public createSubscription(requestParameters: SubscriptionApiCreateSubscriptionRequest, options?: RawAxiosRequestConfig) {
        return SubscriptionApiFp(this.configuration).createSubscription(requestParameters.subscriptionBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SubscriptionApiUpdateSubscriptionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public updateSubscription(requestParameters: SubscriptionApiUpdateSubscriptionRequest, options?: RawAxiosRequestConfig) {
        return SubscriptionApiFp(this.configuration).updateSubscription(requestParameters.id, requestParameters.updateSubscriptionBody, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TokenApi - axios parameter creator
 * @export
 */
export const TokenApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cleanTokens: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tokens/clean`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TokenApi - functional programming interface
 * @export
 */
export const TokenApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TokenApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cleanTokens(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cleanTokens(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TokenApi.cleanTokens']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TokenApi - factory interface
 * @export
 */
export const TokenApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TokenApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cleanTokens(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.cleanTokens(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TokenApi - object-oriented interface
 * @export
 * @class TokenApi
 * @extends {BaseAPI}
 */
export class TokenApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenApi
     */
    public cleanTokens(options?: RawAxiosRequestConfig) {
        return TokenApiFp(this.configuration).cleanTokens(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {UserBody} userBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (userBody: UserBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userBody' is not null or undefined
            assertParamExists('createUser', 'userBody', userBody)
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKpiUser: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/kpi`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: async (limit: number, offset: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('getUsers', 'limit', limit)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('getUsers', 'offset', offset)
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateUserPasswordBody} updateUserPasswordBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePassword: async (id: string, updateUserPasswordBody: UpdateUserPasswordBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePassword', 'id', id)
            // verify required parameter 'updateUserPasswordBody' is not null or undefined
            assertParamExists('updatePassword', 'updateUserPasswordBody', updateUserPasswordBody)
            const localVarPath = `/users/{id}/password`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserPasswordBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateUserBody} updateUserBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (id: string, updateUserBody: UpdateUserBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateUser', 'id', id)
            // verify required parameter 'updateUserBody' is not null or undefined
            assertParamExists('updateUser', 'updateUserBody', updateUserBody)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {UserBody} userBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(userBody: UserBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(userBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.createUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getKpiUser(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KpiUserView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getKpiUser(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.getKpiUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsers(limit: number, offset: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListUserView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsers(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.getUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateUserPasswordBody} updateUserPasswordBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePassword(id: string, updateUserPasswordBody: UpdateUserPasswordBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePassword(id, updateUserPasswordBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.updatePassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateUserBody} updateUserBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(id: string, updateUserBody: UpdateUserBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(id, updateUserBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.updateUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @param {UserApiCreateUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(requestParameters: UserApiCreateUserRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserView> {
            return localVarFp.createUser(requestParameters.userBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKpiUser(options?: RawAxiosRequestConfig): AxiosPromise<KpiUserView> {
            return localVarFp.getKpiUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserApiGetUsersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(requestParameters: UserApiGetUsersRequest, options?: RawAxiosRequestConfig): AxiosPromise<ListUserView> {
            return localVarFp.getUsers(requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserApiUpdatePasswordRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePassword(requestParameters: UserApiUpdatePasswordRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserView> {
            return localVarFp.updatePassword(requestParameters.id, requestParameters.updateUserPasswordBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserApiUpdateUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(requestParameters: UserApiUpdateUserRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserView> {
            return localVarFp.updateUser(requestParameters.id, requestParameters.updateUserBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createUser operation in UserApi.
 * @export
 * @interface UserApiCreateUserRequest
 */
export interface UserApiCreateUserRequest {
    /**
     * 
     * @type {UserBody}
     * @memberof UserApiCreateUser
     */
    readonly userBody: UserBody
}

/**
 * Request parameters for getUsers operation in UserApi.
 * @export
 * @interface UserApiGetUsersRequest
 */
export interface UserApiGetUsersRequest {
    /**
     * 
     * @type {number}
     * @memberof UserApiGetUsers
     */
    readonly limit: number

    /**
     * 
     * @type {number}
     * @memberof UserApiGetUsers
     */
    readonly offset: number
}

/**
 * Request parameters for updatePassword operation in UserApi.
 * @export
 * @interface UserApiUpdatePasswordRequest
 */
export interface UserApiUpdatePasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof UserApiUpdatePassword
     */
    readonly id: string

    /**
     * 
     * @type {UpdateUserPasswordBody}
     * @memberof UserApiUpdatePassword
     */
    readonly updateUserPasswordBody: UpdateUserPasswordBody
}

/**
 * Request parameters for updateUser operation in UserApi.
 * @export
 * @interface UserApiUpdateUserRequest
 */
export interface UserApiUpdateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UserApiUpdateUser
     */
    readonly id: string

    /**
     * 
     * @type {UpdateUserBody}
     * @memberof UserApiUpdateUser
     */
    readonly updateUserBody: UpdateUserBody
}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {UserApiCreateUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createUser(requestParameters: UserApiCreateUserRequest, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).createUser(requestParameters.userBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getKpiUser(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).getKpiUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserApiGetUsersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUsers(requestParameters: UserApiGetUsersRequest, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).getUsers(requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserApiUpdatePasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updatePassword(requestParameters: UserApiUpdatePasswordRequest, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).updatePassword(requestParameters.id, requestParameters.updateUserPasswordBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserApiUpdateUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUser(requestParameters: UserApiUpdateUserRequest, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).updateUser(requestParameters.id, requestParameters.updateUserBody, options).then((request) => request(this.axios, this.basePath));
    }
}



